import {SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const printLaosLabelApi = (data) => {
    const orderId = {
        orderId: data,
    }
    return httpClient.post(`/print/pdf/laos`, orderId)
}
