import * as React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";


import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-image-lightbox/style.css';
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import "nprogress/nprogress.css"
import './App.css';



import {ReactNotifications} from "react-notifications-component";
import {FlashTrackingStatus, JntTrackingStatus, KerryTrackingStatus, ThaiPostTrackingStatus} from "./common/config/app";

// import Login from "./components/authentication/Login";
// import Admin from "./components/admin/Admin";
// import User from "./components/admin/user/User";
// import UserCreate from "./components/admin/user/UserCreate";
// import OrderView from "./components/admin/order/OrderView";
// import ReportMonthy from "./components/admin/report/ReportMonthy";
// import Expense from "./components/admin/expense/Expense";
// import Order from "./components/admin/order/Order";
// import ReportOrder from "./components/admin/report/ReportOrder";
// import ReportCommission from "./components/admin/report/ReportCommission";
// import ReportShipping from "./components/admin/report/ReportShipping";
// import ReportDaily from "./components/admin/report/ReportDaily";
// import FlashExpressNotification from "./components/admin/shipping/flash/FlashExpressNotification";
// import FlashPrintHistory from "./components/admin/shipping/flash/FlashPrintHistory";
// import Logout from "./components/authentication/Logout";
// import CodChecker from "./components/admin/shipping/CodChecker";
// import Address from "./components/admin/address/Address";
// import CodReport from "./components/admin/shipping/flash/CodReport";
// import POD from "./components/admin/shipping/flash/POD";
// import JntOrder from "./components/admin/shipping/jnt/JntOrder";
// import LaosOrders from "./components/admin/shipping/laos/LaosOrders";
import DomainAuth from "./components/admin/DomainAuth";
// import SettingGeneral from "./components/admin/setting/SettingGeneral";
// import OrderOpen from "./components/admin/order/OrderOpen";
// import ThaipostOrder from "./components/admin/shipping/thaipost/ThaipostOrder";
// import OrderOpenManual from "./components/admin/order/OrderOpenManual";
// import OrderImport from "./components/admin/order/OrderImport";
// import KerryOrder from "./components/admin/shipping/kerry/KerryOrder";
import PageDenied from "./components/PageDenied";
import {Spinner} from "react-bootstrap";
import NProgress from 'nprogress'
import {useEffect} from "react";
// import AddressKerry from "./components/admin/setting/AddressKerry";

// import Dashboard from "./components/admin/dashboard/Dashboard";


// const Product = React.lazy(() => import('./components/admin/product/Product'));
// const Category = React.lazy(() => import('./components/admin/category/Category'));
// const ExpenseFacebook = React.lazy(() => import('./components/admin/expense/Expense'));
// const ExpenseShipping = React.lazy(() => import('./components/admin/expense/Expense'));
//
// const OrderSend = React.lazy(() => import('./components/admin/order/Order'));
// const OrderSuccess = React.lazy(() => import('./components/admin/order/Order'));

// import Category from "./components/admin/category/Category";
// import Product from "./components/admin/product/Product";

const LazyLoad = () => {
    useEffect(() => {
        NProgress.start();
        return () => {
            NProgress.done();
        };
    })

    return ''
}

/*Auth*/
const Login = React.lazy(() => import('./components/authentication/Login'))
const Logout = React.lazy(() => import('./components/authentication/Logout'))

const Admin = React.lazy(() => import('./components/admin/Admin'))
const Dashboard = React.lazy(() => import('./components/admin/dashboard/Dashboard'))
const Product = React.lazy(() => import('./components/admin/product/Product'))
const ProductInOut = React.lazy(() => import('./components/admin/product/ProductInOut'))
const Category = React.lazy(() => import('./components/admin/category/Category'))

/*ORDER*/
const OrderOpenManual = React.lazy(() => import('./components/admin/order/OrderOpenManual'))
const Order = React.lazy(() => import('./components/admin/order/Order'))
const OrderOpen = React.lazy(() => import('./components/admin/order/OrderOpen'))
const OrderView = React.lazy(() => import('./components/admin/order/OrderView'))
const OrderImport = React.lazy(() => import('./components/admin/order/OrderImport'))
const OrderTeleSale = React.lazy(() => import('./components/admin/order/OrderTeleSale'))

/*Shipping*/
const JntOrder = React.lazy(() => import('./components/admin/shipping/jnt/JntOrder'))
const ThaipostOrder = React.lazy(() => import('./components/admin/shipping/thaipost/ThaipostOrder'))
const LaosOrders = React.lazy(() => import('./components/admin/shipping/laos/LaosOrders'))
const KerryOrder = React.lazy(() => import('./components/admin/shipping/kerry/KerryOrder'))

const POD = React.lazy(() => import('./components/admin/shipping/flash/POD'))
const FlashExpressNotification = React.lazy(() => import('./components/admin/shipping/flash/FlashExpressNotification'))
const FlashPrintHistory = React.lazy(() => import('./components/admin/shipping/flash/FlashPrintHistory'))
const CodChecker = React.lazy(() => import('./components/admin/shipping/CodChecker'))
const CodReport = React.lazy(() => import('./components/admin/shipping/flash/CodReport'))

/*OTHER*/
const Expense = React.lazy(() => import('./components/admin/expense/Expense'))

/*REPORT*/
const ReportMonthy = React.lazy(() => import('./components/admin/report/ReportMonthy'))
const ReportCommission = React.lazy(() => import('./components/admin/report/ReportCommission'))
const ReportOrder = React.lazy(() => import('./components/admin/report/ReportOrder'))
const ReportShipping = React.lazy(() => import('./components/admin/report/ReportShipping'))
const ReportDaily = React.lazy(() => import('./components/admin/report/ReportDaily'))
const ReportProduct = React.lazy(() => import('./components/admin/report/ReportProduct'))


/*User*/
const User = React.lazy(() => import('./components/admin/user/User'))
const UserCreate = React.lazy(() => import('./components/admin/user/UserCreate'))


/*Setting*/
const AddressKerry = React.lazy(() => import('./components/admin/setting/AddressKerry'))
const AddressThaipost = React.lazy(() => import('./components/admin/setting/AddressThaipost'))
const Address = React.lazy(() => import('./components/admin/address/Address'))
const SettingGeneral = React.lazy(() => import('./components/admin/setting/SettingGeneral'))


function App() {
    return (
        <>
            <Router>
                <React.Suspense fallback={<LazyLoad/>}>
                    <Routes>

                        <Route exact={true} path="/login" element={<Login/>}/>
                        <Route exact={true} path="/logout" element={<Logout/>}/>
                        <Route path="/" element={<Admin/>}>
                            {/*<Route path="/container" element={<Container/>}/>*/}
                            <Route path="/dashboard" element={<Dashboard/>}/>
                            <Route path="/" element={<Dashboard/>}/>
                            {/*<Route path="/" element={<Order status="send"/>}/>*/}

                            <Route path="/category" element={<Category/>}/>
                            <Route path="/product" element={<Product/>}/>
                            <Route path="/product/stock" element={<ProductInOut/>}/>

                            <Route exact path="/expense/facebook" element={<Expense type="facebook"/>}/>
                            <Route exact path="/expense/shipping" element={<Expense type="shipping"/>}/>
                            <Route exact path="/expense/other" element={<Expense type="other"/>}/>

                            <Route path="/order/import" element={<OrderImport/>}/>
                            <Route path="/order/telesale" element={<OrderTeleSale/>}/>
                            <Route path="/order/open" element={<OrderOpen/>}/>
                            <Route path="/order/create" element={<OrderOpenManual/>}/>
                            <Route path="/order/:id/view" element={<OrderView/>}/>
                            <Route path="/order/send" element={<Order status="send"/>}/>
                            <Route path="/order/pending" element={<Order status="pending"/>}/>
                            <Route path="/order/success" element={<Order status="sending"/>}/>
                            {/*<Route path="/order/salepage" element={<Order status="salepage"/>}/>*/}


                            {FlashTrackingStatus.map(v => (
                                <Route key={v.c} path={`/order/tracking/${v.c}`} element={
                                    <Order code={v.c} mode={`flash`} status="tracking"/>}/>
                            ))}

                            {JntTrackingStatus.map(v => (
                                <Route key={v.c} path={`/order/tracking/jnt/${v.c}`} element={
                                    <Order code={v.c} mode={`jnt`} statusTracking={v.code} status="tracking"/>}/>
                            ))}

                            {KerryTrackingStatus.map(v => (
                                <Route key={v.c} path={`/order/tracking/kerry/${v.c}`} element={
                                    <Order code={v.c} mode={`kerry`} statusTracking={v.code} status="tracking"/>}/>
                            ))}

                            {ThaiPostTrackingStatus.map(v => (
                                <Route key={v.c} path={`/order/tracking/thaipost/${v.c}`} element={
                                    <Order code={v.c} mode={`thaipost`} statusTracking={v.code} status="tracking"/>}/>
                            ))}

                            <Route path="/order/cancel" element={<Order status="cancel"/>}/>
                            <Route path="/order/return" element={<Order status="return"/>}/>

                            <Route path="/user" element={<User role="admin-emp"/>}/>
                            <Route path="/user/create" element={<UserCreate/>}/>
                            <Route path="/user/:id/edit" element={<UserCreate/>}/>

                            <Route path="/member" element={<User role="member"/>}/>

                            <Route path="/report/monthly" element={<ReportMonthy/>}/>
                            <Route path="/report/order" element={<ReportOrder/>}/>
                            <Route path="/report/commission" element={<ReportCommission/>}/>
                            <Route path="/report/shipping" element={<ReportShipping/>}/>
                            <Route path="/report/daily" element={<ReportDaily/>}/>
                            <Route path="/report/product" element={<ReportProduct/>}/>

                            <Route path="/flash-express/notifications" element={
                                <DomainAuth shipping="flash"><FlashExpressNotification/></DomainAuth>}/>
                            <Route path="/flash-express/print" element={
                                <DomainAuth shipping="flash"><FlashPrintHistory/></DomainAuth>}/>

                            <Route path="/cod-checker" element={<CodChecker/>}/>

                            {/*<Route path="/flash-express/ka/cod-bills" element={<KACodBills/>}/>*/}
                            <Route path="/flash-express/ka/cod" element={
                                <DomainAuth shipping="flash"><CodReport/></DomainAuth>}/>
                            <Route path="/flash-express/ka/pod" element={
                                <DomainAuth shipping="flash"><POD/></DomainAuth>}/>


                            <Route path="/jnt/orders" element={<DomainAuth shipping="jnt"><JntOrder/></DomainAuth>}/>
                            <Route path="/laos/orders" element={
                                <DomainAuth shipping="laos"><LaosOrders/></DomainAuth>}/>
                            <Route path="/thaipost/orders" element={
                                <DomainAuth shipping="thaipost"><ThaipostOrder/></DomainAuth>}/>


                            <Route path="/kerry/orders" element={
                                <DomainAuth shipping="kerry"><KerryOrder/></DomainAuth>}/>


                            <Route path="/print-history" element=<FlashPrintHistory/>/>

                            <Route exact path="/address" element={<Address/>}/>

                            <Route exact path="/setting/general" element={<SettingGeneral/>}/>
                            <Route exact path="/setting/address/kerry" element={<AddressKerry/>}/>
                            <Route exact path="/setting/address/thaipost" element={<AddressThaipost/>}/>
                            {/*<Route path="/flash-express/printed" element={<ReportShipping type="printed"/>}/>*/}
                            {/*<Route path="/flash-express/print-wait" element={<ReportShipping type="print-wait"/>}/>*/}

                        </Route>
                        <Route exact path="/denied" element={<PageDenied/>}/>
                    </Routes>
                </React.Suspense>
            </Router>
            <ReactNotifications/>
        </>
    );
}

export default App;
