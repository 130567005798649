    import {createSlice} from "@reduxjs/toolkit";
import {fetchAsyncCategory} from "./categoryThunk";


const initialState = {
    items: {
        data: [],
    },
    showModal: false
}


const categorySlice = createSlice({
    name: `category`,
    initialState: initialState,
    reducers: {
        setCategoryShowModal : (state, {payload}) => {
            return {...state, showModal: payload}
        },
    },
    extraReducers: {
        [fetchAsyncCategory.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                items: payload
            }
        }
    },
})


export const getCategories = (state) => state.category.items
export const showCategoryModal = (state) => state.category.showModal
export const {setCategoryShowModal} = categorySlice.actions
export default categorySlice.reducer
