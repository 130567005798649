import {setDataParams, SetGateWay} from "./gateway";
import localStorageService from "../../services/localStorageService";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay
export const createThaipostOrderApi = (order, data) => {
    const localStorage = localStorageService.getService()
    const domain = localStorage.getConfig()
    const orderId = {
        orderId: order,
        address: data.address,
        prefix: domain?.prefix ?? '',
    }
    // console.log(orderId)
    return httpClient.post(`/thaipost/order/create`, orderId)
}

export const cancelThaipostOrderApi = (data) => {
    const orderId = {
        orderId: data
    }
    return httpClient.post(`/thaipost/order/cancel`, setDataParams(orderId))
}
export const updateThaipostTrackingOrderApi = (data) => {
    return httpClient.post(`/thaipost/order/tracking/update`, setDataParams(data))
}


export const trackingThaipostOrderApi = (id) => {
    return httpClient.get(`/thaipost/order/tracking/${id}`)
}

export const printThaipostOrderApi = (data) => {
    const orderId = {
        orderId: data.orderId,
        type: data.type
    }
    return httpClient.post(`/thaipost/order/print`, setDataParams(orderId))
}

