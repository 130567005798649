import {createSlice} from "@reduxjs/toolkit";
import {fetchAsyncAddress, fetchAsyncAddressDetect} from "./addressThunk";


const initialState = {
    items: {
        data: [],
    },
    addressDetect: [],
}


const addressSlice = createSlice({
    name: `address`,
    initialState: initialState,
    reducers: {
        setAddressShowModal: (state, {payload}) => {
            return {...state, showModal: payload}
        },
    },
    extraReducers: {
        [fetchAsyncAddress.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                items: payload
            }
        },
        [fetchAsyncAddressDetect.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                addressDetect: payload
            }
        }
    },
})


export const getAddresses = (state) => state.address.items
export const getAddressesDetect = (state) => state.address.addressDetect

export default addressSlice.reducer

