import {useSelector} from "react-redux";
import {userConfig} from "../../features/reducer/user/userSlice";
import {useEffect, useState} from "react";
import {decryptJson} from "../../common/functions/encrypt";

const DomainAuth = ({children, shipping = `flash`}) => {
    const uConfig = useSelector(userConfig)
    const [config, setConfig] = useState(uConfig)
    const [allow, setAllow] = useState(false)

    useEffect(() => {
        if (uConfig !== null && uConfig !== ``) {
            setConfig(decryptJson(uConfig))
        }
    }, [uConfig])

    useEffect(() => {
        if (typeof config?.shippingFlash !== `undefined`) {
            switch (shipping) {
                case `flash`:
                    setAllow(config?.shippingFlash === 1)
                    break
                case `jnt`:
                    setAllow(config?.shippingJnt === 1)
                    break
                case `laos`:
                    setAllow(config?.shippingLaos === 1)
                    break
                case `thaipost`:
                    setAllow(config?.shippingThaiPost === 1)
                    break
                case `kerry`:
                    setAllow(config?.shippingKerry === 1)
                    break
            }
        }
    }, [config])


    return (
        <>
            {allow && children}
        </>
    )
}

export default DomainAuth
