import {ExpenseType, OrderStatus} from "../config/app";

export function rowRuning(i, page, pageSize) {
    // return pageSize
    if (typeof page == 'undefined' || page == 1 || page == 0 || page == null || isNaN(page)) {
        return i + 1
    } else {
        const n = (pageSize * page) - parseInt(pageSize) + 1
        return parseInt(n) + (parseInt(i))
    }
}


export function expenseTypeName(type) {
    return typeof ExpenseType[type] !== `undefined` ? ExpenseType[type] : ``
}


export function orderStatusName(status) {
    // const index = OrderStatus.indexOf(status)
    const order = OrderStatus.find(element => element.status === status);
    return order?.name

}


export function getSubDomain() {
    const host = window.location.host
    const subdomain = host.split('.')[0]
    return subdomain;
}


export function checkPrefixDomain(prefix) {

}


export function formatCurrency(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
}
