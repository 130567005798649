import {createSlice} from "@reduxjs/toolkit";
import {cancelAsyncThaipostOrder, createAsyncThaipostOrder, printAsyncThaipostOrder, trackingAsyncThaipostOrder} from "./thaipostThunk";

const initialState = {
    selectedItems: [],
    tracking: {},
    pdf: ``,
}
const thaipostSlice = createSlice({
    name: `thaipost`,
    initialState: initialState,
    reducers: {
        setSelectedOrderItems: (state, {payload}) => {
            return {
                ...state,
                selectedItems: payload
            }
        },
        setPdfUrl: (state, {payload}) => {
            return {
                ...state,
                pdf: payload
            }
        },
    },
    extraReducers: {
        [createAsyncThaipostOrder.fulfilled]: (state, {payload}) => {
            if (typeof payload !== `undefined` && payload.length > 0) {
                return {
                    ...state,
                    selectedItems: state.selectedItems.map(order => {
                        let o = order
                        payload.map(p => {
                            const item = p
                            if (p?.OrderId == order.id) {
                                if (item?.StatusCode === "S0000" && item?.Barcode !== ``) {
                                    o = {
                                        ...order,
                                        pno: item?.Barcode ?? ``,
                                        t: `success`,
                                        error: null,
                                        errMessage: null
                                    }
                                } else {
                                    o = {
                                        ...order,
                                        pno: '',
                                        t: 'error',
                                        error: ``,
                                        errMessage: item?.StatusMessage
                                    }
                                }
                            }
                        })
                        return o
                    })
                }
            }
        },
        [cancelAsyncThaipostOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                selectedItems: state.selectedItems.map(order => {
                    let o = order
                    payload.map(p => {
                        // const item = p[0] ?? null
                        if (p.id == order.id) {
                            if (p.pno === "") {
                                o = {...p, cancelTracking: "success"}
                            } else {
                                o = {...p, cancelTracking: "error"}
                            }
                        }
                    })
                    return o
                })
            }
        },
        [trackingAsyncThaipostOrder.fulfilled]: (state, {payload}) => {
            // console.log(payload?.responseitems[0]?.tracesList[0]?.details)
            if (typeof payload?.responseitems !== `undefined` && payload?.responseitems?.length > 0 && Array.isArray(payload?.responseitems)) {
                return {...state, tracking: payload?.responseitems[0]?.tracesList[0]?.details}
            }

        },
        [printAsyncThaipostOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                pdf: payload.url
            }
        },
    },
})

export const getSelectedOrderItems = (state) => state.thaipost.selectedItems
export const getTracking = (state) => state.thaipost.tracking
export const getPdfUrl = (state) => state.thaipost.pdf
export const thaipostState = (state) => state.thaipost
export const {setSelectedOrderItems, setPdfUrl} = thaipostSlice.actions
export default thaipostSlice.reducer
