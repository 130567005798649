import {httpObjQueryString} from "../config/httpClient";
import {setDataParams, SetGateWay} from "./gateway";
import localStorageService from "../../services/localStorageService";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay
export const createJntOrderApi = (order, data) => {
    const localStorage = localStorageService.getService()
    const domain = localStorage.getConfig()
    const orderId = {
        orderId: order,
        address: data.address,
        prefix: domain?.prefix ?? '',
    }
    // console.log(orderId)
    return httpClient.post(`/jnt/order/create`, orderId)
}

export const cancelJntOrderApi = (data) => {
    const orderId = {
        orderId: data
    }
    return httpClient.post(`/jnt/order/cancel`, setDataParams(orderId))
}


export const trackingJntOrderApi = (id) => {
    return httpClient.get(`/jnt/order/tracking/${id}`)
}

export const printJntOrderApi = (data) => {
    const orderId = {
        orderId: data.orderId,
        type: data.type
    }
    return httpClient.post(`/jnt/order/print`, setDataParams(orderId))
}

