import {createSlice} from "@reduxjs/toolkit";
import {cancelAsyncKerryOrder, createAsyncKerryOrder, printAsyncKerryOrder, trackingAsyncKerryOrder} from "./kerryThunk";

const initialState = {
    selectedItems: [],
    tracking: {},
    pdf: ``,
}
const kerrySlice = createSlice({
    name: `kerry`,
    initialState: initialState,
    reducers: {
        setSelectedOrderItems: (state, {payload}) => {
            return {
                ...state,
                selectedItems: payload
            }
        },
        setPdfUrl: (state, {payload}) => {
            return {
                ...state,
                pdf: payload
            }
        },
    },
    extraReducers: {
        [createAsyncKerryOrder.fulfilled]: (state, {payload}) => {
            if (typeof payload !== `undefined` && payload.length > 0) {
                return {
                    ...state,
                    selectedItems: state.selectedItems.map(order => {
                        let o = order
                        payload.map(p => {
                            const item = p?.res?.shipment ?? null
                            if (p?.refNo == order.id) {
                                if (item?.status_code === "000" || item?.status_code === "200") {
                                    o = {
                                        ...order,
                                        pno: item?.con_no ?? ``,
                                        t: `success`,
                                        error: null,
                                        errMessage: null
                                    }
                                } else {
                                    o = {
                                        ...order,
                                        pno: '',
                                        t: 'error',
                                        error: ``,
                                        errMessage: item?.status_desc
                                    }
                                }
                            }
                        })
                        return o
                    })
                }
            }
        },
        [cancelAsyncKerryOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                selectedItems: state.selectedItems.map(order => {
                    let o = order
                    payload.map(p => {
                        // const item = p[0] ?? null
                        if (p.id == order.id) {
                            if (p.pno === "") {
                                o = {...p, cancelTracking: "success"}
                            } else {
                                o = {...p, cancelTracking: "error"}
                            }
                        }
                    })
                    return o
                })
            }
        },
        [trackingAsyncKerryOrder.fulfilled]: (state, {payload}) => {
            // console.log(payload?.responseitems[0]?.tracesList[0]?.details)
            if (typeof payload?.responseitems !== `undefined` && payload?.responseitems?.length > 0 && Array.isArray(payload?.responseitems)) {
                return {...state, tracking: payload?.responseitems[0]?.tracesList[0]?.details}
            }

        },
        [printAsyncKerryOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                pdf: payload.url
            }
        },
    },
})

export const getSelectedOrderItems = (state) => state.kerry.selectedItems
export const getTracking = (state) => state.kerry.tracking
export const getPdfUrl = (state) => state.kerry.pdf
export const kerryState = (state) => state.kerry
export const {setSelectedOrderItems, setPdfUrl} = kerrySlice.actions
export default kerrySlice.reducer
