import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";

import userReducer from './reducer/user/userSlice'
import categoryReducer from './reducer/category/categorySlice'
import productReducer from './reducer/product/productSlice'
import expenseReducer from './reducer/expense/expenseSlice'
import addressReducer from './reducer/address/addressSlice'
import orderReducer from './reducer/order/orderSlice'
import reportReducer from './reducer/report/reportSlice'
import flashReducer from './reducer/flashExpress/flashExpressSlice'
import laosReducer from './reducer/laos/laosSlice'
import jntReducer from './reducer/jnt/jntSlice'
import kerryReducer from './reducer/kerry/kerrySlice'
import thaipostReducer from './reducer/thaipost/thaipostSlice'
import settingReducer from './reducer/setting/settingSlice'
import utilityReducer from './reducer/utility'

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})
export const store = configureStore({
    reducer: {
        utility: utilityReducer,
        users: userReducer,
        category: categoryReducer,
        product: productReducer,
        expense: expenseReducer,
        address: addressReducer,
        order: orderReducer,
        report: reportReducer,
        flashExpress: flashReducer,
        laos: laosReducer,
        jnt: jntReducer,
        kerry: kerryReducer,
        thaipost: thaipostReducer,
        setting: settingReducer,
    }, middleware: customizedMiddleware,
})
