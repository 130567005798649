import {createAsyncThunk} from "@reduxjs/toolkit";
import {deleteExpenseApi, fetchExpenseApi, newExpenseApi, updateExpenseApi} from "../../../common/api/expenseApi";
import {notificationSuccessMessage} from "../../../common/config/message";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {setModal, setProgressDialog} from "../utility";

export const fetchAsyncExpense = createAsyncThunk('expense/fetchAsyncExpense', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        // const response = await fetchExpenseApi(filter)
        const response = await httpRequestFunc(baseThunk,  fetchExpenseApi(filter))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        console.log(e)
    }
})


export const addAsyncExpense = createAsyncThunk('expense/addAsyncExpense', async ({expense, filter}, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, typeof expense.id !== `undefined` ? updateExpenseApi(expense) : newExpenseApi(expense))
        if (response.status === 200) {
            notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
            baseThunk.dispatch(fetchAsyncExpense(filter))
        }
    } catch (e) {
        console.log(e)
    }
})


export const deleteAsyncExpense = createAsyncThunk('expense/deleteAsyncExpense', async (expense, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, deleteExpenseApi(expense.id))
        if (response.status === 200) {
            notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
            const filter = {
                type: expense.type
            }
            baseThunk.dispatch(fetchAsyncExpense(filter))
        }
    } catch (e) {
        console.log(e)
    }
})
