import {createAsyncThunk} from "@reduxjs/toolkit";
import {setLoading, setModal, setProgressDialog} from "../utility";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {notificationSuccessMessage} from "../../../common/config/message";
import {printLaosLabelApi} from "../../../common/api/laosApi";

export const printAsyncLaosLabel = createAsyncThunk('laos/printAsyncLaosLabel', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, printLaosLabelApi(data.orderId))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})
