// import {NotificationManager} from "react-light-notifications";
import {Store} from 'react-notifications-component';
import {APP_DEBUG} from "./app";

const message = {
    TOKEN_MISSING: "เข้าใช้งานไม่ถูกต้อง",
    UNAUTHORIZED: "ไม่มีสิทธิ์เข้าใช้งาน",
    INTERNAL_ERROR: "เกิดข้อผิดพลาดบางอย่าง",
    BAD_REQUEST: "กรุณากรอกข้อมูลให้ครบ",
    UNPROCESSED: "ไม่สามารถประมวลผลข้อมูลได้",
    UNPROCESS: "ไม่สามารถประมวลผลข้อมูลได้",
    INVALID_REFRESH_TOKEN: "Refresh token ไม่ถูกต้อง",

    SIGN_TOKEN_ERROR: "เข้ารหัสผิดพลาด",
    SIGN_TOKEN_EMPTY: "SIGN_TOKEN_EMPTY",
    INVALID_ROLE: "INVALID_ROLE",
    TOO_MANY_LOGIN: "เข้าสู้ระบบผิดพลาดหลายครั้ง",
    LOGIN_FAILS: "เข้าสู้ระบบผิดพลาด",
    PERMISSION_DENIED: "คุณไม่มีสิทธิ์ทำรายการ",
    USER_NOT_FOUND: "ไม่พบผู้ใช้งาน",
    USER_EXIST: "ชื่อเข้่าใช้งานมีในระบบแล้ว",
    PWD_IS_SHORT: "รหัสผ่าน 6 หลักขึ้นไป",
    PWD_NOT_MATCH: "PWD_NOT_MATCH",
    PWD_INCORRECT: "รหัสผ่านไม่ถูกต้อง",

    CAT_NOT_FOUND: "หมวดหมู่สินค้าไม่ถูกต้อง",
    NO_ORDER: "ไม่พบคำสั่งซื้อที่สามารถทำได้การได้",
    CONF_NOT_FOUND: "โดเมนยังไม่ได้ลงทะเบียน",

    ERR_ORDER_SET_DISCOUNT: "สินค้าที่เป็นเซ็ตไม่สามารถใส่ส่วนลดได้",
    ERR_ORDER_SET_FREE: "สินค้าที่เป็นเซ็ตไม่สามารถกำหนดเป็นสินค้าฟรีได้",
    ERR_ORDER_SET_DUPLICATE: "ไม่สามารถเพิ่มรายการสินค้าเซ็ตได้ เนื่องจากมีในรายการแล้ว กรุณาลบรายการเดิมออก ก่อนเพิ่มใหม่",

    PRINT_ERR: "พิมพ์ใบปะหน้าไม่สำเร็จ",
    ERR_PRODUCT_CODE_EXIST: "รหัสสินค้าถูกใช้งานแล้ว",

    NO_RECEIPT_INFO: "ไม่พบข้อมูลรานค้า กรุณาตั้งค่าที่เมนูตั้งค่าก่อนพิมพ์ใบเสร็จ",

    NOT_FOUND: "ไม่สามารถทำรายการได้ เนื่องจากไม่พบข้อมูล",
}


export const notificationErrorMessage = (err) => {
    const data = err?.response?.data
    if (typeof data != `undefined` && data != `` && data != null) {
        let msg = message[data.message]
        if (typeof msg == `undefined`)
            msg = `ไม่สามารถทำรายการได้ ลองใหม่อีกครั้ง`

        // console.log(data?.message)
        if (typeof data?.type !== `undefined` && data?.type === 1) {
            msg = data?.message

        }
        notificationErrorCustomMessage(msg, 'warning')
    } else {
        notificationErrorCustomMessage(`เกิดข้อผิดพลาดบางอย่าง`, 'error')
    }

    if (process.env.REACT_APP_DEBUG) {
        console.log(err)
    }
}

export const notificationSuccessMessage = (message) => {
    notificationErrorCustomMessage(message, 'success')
}

export const notificationErrorCustomMessage = (message, status = `error`) => {

    // alert(555)

    // return
    //
    // switch (status) {
    //     case `error`:
    //         NotificationManager.error({
    //             title: "แจ้งเตือน",
    //             message: message,
    //         });
    //         break
    //     case `warning` :
    //         NotificationManager.warning({
    //             title: "แจ้งเตือน",
    //             message: message,
    //         });
    //         break
    //     case`info`:
    //         NotificationManager.info({
    //             title: "แจ้งเตือน",
    //             message: message,
    //         });
    //         break
    // }
    let st = status
    if (st == `error`) {
        st = `danger`
    }
    Store.addNotification({
        title: "แจ้งเตือน!",
        message: message,
        type: st,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__bounceInRight"],
        animationOut: ["animate__animated", "animate__bounceOutRight"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}
