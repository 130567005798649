import {createAsyncThunk} from "@reduxjs/toolkit";
import {setModal, setOrderDuplicate, setProgressDialog, setSubmit} from "../utility";
import {
    checkDuplicateOrderApi,
    deleteOrderApi,
    deleteOrderItemApi,
    fetchOrderApi,
    getOrderApi,
    getOrderMessageApi,
    newOrderApi,
    openOrderApi,
    openOrderImportApi,
    openOrderManualApi,
    printOrderReceiptApi,
    rollbackOrderApi,
    updateOrderApi,
    updateOrderInfoApi,
    updateOrderItemApi,
    updateOrderSendStatusApi,
    updateOrderShippingApi,
    updateOrderStatusApi
} from "../../../common/api/orderApi";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {notificationSuccessMessage} from "../../../common/config/message";
// import {setOpenOrder} from "./orderSlice";

export const fetchAsyncOrder = createAsyncThunk('order/fetchAsyncOrder', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, fetchOrderApi(filter))
        if (typeof filter.hideModal === `undefined`) {
            baseThunk.dispatch(setModal(false))
        }

        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})

export const getAsyncOrder = createAsyncThunk('order/getAsyncOrder', async (id, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, getOrderApi(id))
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const addAsyncOrder = createAsyncThunk('order/addAsyncOrder', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, typeof order.id !== `undefined` ? updateOrderApi(order) : newOrderApi(order))
        baseThunk.dispatch(fetchAsyncOrder())
        return response.data
    } catch (e) {
        console.log(e)
    }
})


export const updateAsyncOrderShipping = createAsyncThunk('order/updateAsyncOrderShipping', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, updateOrderShippingApi(order))
        baseThunk.dispatch(getAsyncOrder(order.id))
        baseThunk.dispatch(setModal(false))
        notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const updateAsyncOrderInfo = createAsyncThunk('order/updateAsyncOrderInfo', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, updateOrderInfoApi(order))
        baseThunk.dispatch(getAsyncOrder(order.id))
        baseThunk.dispatch(setModal(false))
        notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const updateAsyncOrderItem = createAsyncThunk('order/updateAsyncOrderItem', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, updateOrderItemApi(order))
        baseThunk.dispatch(getAsyncOrder(order.id))
        baseThunk.dispatch(setModal(false))
        notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})


export const deleteAsyncOrder = createAsyncThunk('order/deleteAsyncOrder', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, deleteOrderApi(order.id))
        const f = {
            page: 1,
            pageSize: 20,
            status: order.status,
        }
        baseThunk.dispatch(fetchAsyncOrder(f))
        notificationSuccessMessage(`ลบรายการสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const rollbackAsyncOrder = createAsyncThunk('order/rollbackAsyncOrder', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, rollbackOrderApi(order.id))
        const f = {
            page: 1,
            pageSize: 20,
            status: order.status,
        }
        baseThunk.dispatch(fetchAsyncOrder(f))
        notificationSuccessMessage(`คืนค่ารายการสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})
export const sendStatusAsyncOrder = createAsyncThunk('order/sendStatusAsyncOrder', async (order, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, updateOrderSendStatusApi(order.id))
        const f = {
            page: 1,
            pageSize: 20,
            status: order.status,
        }
        baseThunk.dispatch(fetchAsyncOrder(f))
        notificationSuccessMessage(`เปลี่ยนสถานะส่งสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})
export const changeMultipleStatusAsyncOrder = createAsyncThunk('order/changeMultipleStatusAsyncOrder', async (data, baseThunk) => {
    try {
        let dt = data, filter = null
        if (typeof data?.filter !== `undefined`) {
            filter = dt.filter
            delete dt.filter
        }
        const response = await httpRequestFunc(baseThunk, updateOrderStatusApi(data))
        if (typeof filter !== `undefined` && filter !== null) {
            baseThunk.dispatch(fetchAsyncOrder(filter))
        }
        notificationSuccessMessage(`เปลี่ยนสถานะสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const deleteAsyncOrderItem = createAsyncThunk('order/deleteAsyncOrderItem', async (item, baseThunk) => {
    // console.log(item)
    try {
        const response = await httpRequestFunc(baseThunk, deleteOrderItemApi(item.id))
        baseThunk.dispatch(getAsyncOrder(item.orderId))
        notificationSuccessMessage(`ลบรายการสำเร็จ`)
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const openAsyncOrder = createAsyncThunk('order/openAsyncOrder', async (data, baseThunk) => {
    // console.log(item)
    try {
        const response = await httpRequestFunc(baseThunk, openOrderApi(data))
        baseThunk.dispatch(getAsyncOrderMessage({page: 1, pageSize: 15}))
        notificationSuccessMessage(`สร้างออเดอร์สำเร็จ`)
        // return response.data
        if (response.status === 200)
            return response.data
    } catch (e) {

    }
})
export const openAsyncOrderManual = createAsyncThunk('order/openAsyncOrderManual', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setSubmit(1))
    try {
        const response = await httpRequestFunc(baseThunk, openOrderManualApi(data))
        notificationSuccessMessage(`สร้างออเดอร์สำเร็จ`)
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setSubmit(2))
        if (response.status === 200)
            return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})
export const openAsyncOrderImport = createAsyncThunk('order/openAsyncOrderImport', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setSubmit(1))
    try {
        const response = await httpRequestFunc(baseThunk, openOrderImportApi(data))
        notificationSuccessMessage(`ส่งข้อมูลออร์เดอร์แล้ว กรุณาตรวจสอบข้อมูลอีกครั้ง`)
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setSubmit(2))
        if (response.status === 200)
            return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})

export const getAsyncOrderMessage = createAsyncThunk('order/getAsyncOrderMessage', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getOrderMessageApi(data))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})


export const printAsyncOrderReceipt = createAsyncThunk('order/printAsyncOrderReceipt', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, printOrderReceiptApi(data))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})


export const checkAsyncOrderDuplicate = createAsyncThunk(
    'order/checkAsyncOrderDuplicate',
    async ({
               name,
               data,
               callback
           }, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        baseThunk.dispatch(setOrderDuplicate(null))
        try {
            const response = await httpRequestFunc(baseThunk, checkDuplicateOrderApi({name: name}))
            if (response.status === 200) {
                baseThunk.dispatch(setProgressDialog(false))
                const dt = response.data
                if (dt !== `OK`) {
                    baseThunk.dispatch(setOrderDuplicate(dt))
                } else {
                    baseThunk.dispatch(setOrderDuplicate(false))
                    baseThunk.dispatch(callback(data))
                }

                return
            }
            console.log(response.data)
        } catch (e) {
            console.log(e)
        }
        baseThunk.dispatch(setProgressDialog(false))
    })
