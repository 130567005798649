import {httpObjQueryString} from "../config/httpClient";
import {checkSubDomain, setDataParams, SetGateWay} from "./gateway";
import {getSubDomain} from "../functions/usage";
import localStorageService from "../../services/localStorageService";


const {httpGateWay, httpGateWayForm} = SetGateWay()
const httpClient = httpGateWay
const httpClientForm = httpGateWayForm

export const fetchProductApi = (filter) => {
    return httpClient.get(`/product?${httpObjQueryString(filter)}`)
}

export const getProductApi = (id) => {
    return httpClient.get(`/product/${id}`)
}

export const newProductApi = (data) => {
    const params = httpObjQueryString(data)
    return httpClientForm.post(`/product`, params)
}

export const updateProductApi = (id, data) => {
    const params = httpObjQueryString(data)
    return httpClientForm.put(`/product/${id}`, params)
}

export const deleteProductApi = (id) => {
    return httpClient.delete(`/product/${id}`)
}
export const deleteProductAllApi = (id) => {
    return httpClient.delete(`/product/all`, {
        data: {id: id}
    })
}

export const productImageSrc = (product) => {
    let url = product?.imageUrl
    let sub = checkSubDomain()

    let subdomain = ``
    let prefix = `/v1`
    if (sub !== `main`) {
        subdomain = `${sub}.`
        prefix = `/${sub}`
    }

    if (process.env.REACT_APP_ENV === `local`) {
        prefix = ``
    }

    if (typeof url !== `undefined` && url != ``) {
        if (url.indexOf(`upload`) !== -1) {
            return `https://media.systemtong.com/${product.imageUrl}`
        } else {
            return `${process.env.REACT_APP_API_BASE}${prefix}/images/${product.imageUrl}`
        }
    }


    if (product?.media)
        return `${process.env.REACT_APP_API_BASE}${prefix}/images/${product.media.name}`

    return ``
}


export const fetchProductStockApi = (filter) => {
    return httpClient.get(`/product/${filter.id}/stock?${httpObjQueryString(filter)}`)
}

export const addProductStockApi = (data) => {
    return httpClient.post(`/product/${data.id}/stock/add`, setDataParams(data))
}
