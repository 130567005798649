import CryptoJS from 'crypto-js';

const privateKey = `>}wFj9J~sQnrK4?Y;Q3qqV`

export const encryptJson = (data) => {
    if (typeof data !== 'undefined') {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), privateKey).toString();
        return ciphertext
    }
}


export const decryptJson = (data) => {
    // console.log(data)
    if (typeof data !== 'undefined') {
        const bytes = CryptoJS.AES.decrypt(data, privateKey);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
}
