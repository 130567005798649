import {httpObjQueryString} from "../config/httpClient";
import {setDataParams, SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const fetchExpenseApi = (filter) => {
    return httpClient.get(`/expense?${httpObjQueryString(filter)}`)
}

export const newExpenseApi = (data) => {
    return httpClient.post(`/expense`, setDataParams(data))
}

export const updateExpenseApi = (data) => {
    return httpClient.put(`/expense/${data.id}`, setDataParams(data))
}

export const deleteExpenseApi = (id) => {
    return httpClient.delete(`/expense/${id}`)
}
