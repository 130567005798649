import {httpObjQueryString} from "../config/httpClient";
import {setDataParams, SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const fetchAddressApi = (filter) => {
    return httpClient.get(`/address?${httpObjQueryString(filter)}`)
}

export const newAddressApi = (data) => {
    return httpClient.post(`/address`, setDataParams(data))
}

export const autoDectactAddressApi = (data) => {
    return httpClient.post(`/address/auto-detection`, setDataParams(data))
}

export const updateAddressApi = (data) => {
    return httpClient.put(`/address/${data.id}`, setDataParams(data))
}

export const deleteAddressApi = (id) => {
    return httpClient.delete(`/address/${id}`)
}


export const detectAddressApi = (address) => {
    return httpClient.get(`/address/detect?address=${address}`)
}
