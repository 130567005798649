import axios from "axios";

import localStorageService from "../../services/localStorageService";
import {notificationErrorMessage} from "./message";
import {setLoading} from "../../features/reducer/utility";
import {setDataParams, SetGateWay} from "../api/gateway";
import NProgress from "nprogress";

let isRefreshing = false;
let failedQueue = [];

const localStorage = localStorageService.getService()

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});
const currentExecutingRequests = {};
httpClient.interceptors.request.use(
    (config) => {

        if (currentExecutingRequests[config.url]) {
            const source = currentExecutingRequests[config.url];
            delete currentExecutingRequests[config.url];
            source.cancel();
        }
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const token = localStorage.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        const cfg = {
            ...config,
            cancelToken: source.token
        };
        currentExecutingRequests[config.url] = source;
        // controller.abort('We gotta cancel this');
        return cfg
    },
    (error) => {
        Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    function (response) {
        if (currentExecutingRequests[response.request.responseURL]) {
            delete currentExecutingRequests[response.request.responseURL];
        }
        return response;
    },
    httpResponse
);


const httpClientFormValue = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
});

httpClientFormValue.interceptors.request.use(
    (config) => {
        const token = localStorage.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = 'application/x-www-form-urlencoded';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
    httpResponse
);

function httpResponse(error) {

    const {config, response} = error;
    const originalRequestConfig = config;

    if (axios.isCancel(error)) {
        return new Promise(() => {
        });
    }
    if (currentExecutingRequests[originalRequestConfig.url]) {
        delete currentExecutingRequests[originalRequestConfig.url];
    }

    const originalRequest = config;

    if (!error.response) {
        console.log("Please check your internet connection.");
        // window.location.href = `/login`
        return Promise.reject(error);
    }

    // console.log(error)
    if (error.response?.status === 401 && originalRequest.url === `/user/refreshToken`) {
        window.location.href = "/login";
        return Promise.reject(error);
    }

    const accessToken = localStorage.getAccessToken();
    const refreshToken = localStorage.getRefreshToken();

    const model = {
        accessToken: accessToken,
        refreshToken: refreshToken,
    };


    if (error.response?.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject});
            }).then(() => {
                originalRequest.headers[
                    "Authorization"
                    ] = `Bearer ${model.accessToken}`;
                return axios(originalRequest);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
            axios.post(`${process.env.REACT_APP_API_BASE}/user/refreshToken`, model, {
                headers: {Authorization: `Bearer ${model.accessToken}`},
            }).then(resp => {
                const token = {
                    accessToken: resp.data.access_token,
                    refreshToken: resp.data.refresh_token,
                }
                localStorage.setToken(token);
                httpClient.defaults.headers.common["Authorization"] = `Bearer ${token.accessToken}`;
                originalRequest.headers["Authorization"] = `Bearer ${token.accessToken}`;
                resolve(axios(originalRequest));
            }).catch((err) => {
                console.log("refresh err:", err);

                processQueue(err, null);

                localStorage.forgetToken();
                window.location.href = "/login";
                reject({status: error.response.status, error: error});
            }).finally(() => {
                isRefreshing = false;
            });
        });
    }

    return Promise.reject(error);
}

export default httpClient;
export const httpClientForm = httpClientFormValue

export const httpRequestFunc = async (baseThunk, callback, popup = true) => {
    // SetGateWay()
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    NProgress.start();
    try {
        const response = await callback
        if (response?.status === 200) {
            setTimeout(() => {
                baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
            }, 500)
            NProgress.done();
            return response
        }
        NProgress.done();
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
    } catch (e) {
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        const data = e?.response?.data
        if (e?.response.status == 403 && data?.message == 'TOKEN_MISSING') {
            return
        }
        if (popup) {
            notificationErrorMessage(e)
        }
        NProgress.done();
        throw baseThunk.rejectWithValue(data?.message)
    }
}

export const httpObjQueryString = (data) => {
    if (typeof data != `undefined`) {
        let dt = setDataParams(data)
        return Object.entries(dt).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
    }

    return ``
}
