import {httpObjQueryString} from "../config/httpClient";
import {checkSubDomain, SetGateWay} from "./gateway";
import moment from "moment";


const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const fetchReportMonthlyApi = (filter, config = {}) => {
    let f = {
        start: filter.start,
        end: filter.end,
        modeUser: filter?.modeUser ?? '',
    }
    let c = {
        ...config,
        params: {
            catId: filter.catId,
            prefix: checkSubDomain()
        }
    }
    return httpClient.get(`/report/monthly?${httpObjQueryString(f)}`, c)
}


export const fetchReportCommissionApi = (filter) => {
    let f = {
        start: filter.start,
        end: filter.end
    }
    return httpClient.get(`/report/commission?${httpObjQueryString(f)}`, {
        params: {
            catId: filter?.catId?.join(','),
            productId: filter?.productId?.join(','),
            prefix: checkSubDomain()
        }
    })
}

export const fetchReportShippingApi = (filter) => {
    let f = {
        ...filter,
        start: filter.start,
        end: filter.end,
        tracking: filter.tracking,
        prefix: checkSubDomain()
    }
    return httpClient.get(`/report/shipping?${httpObjQueryString(f)}`)
}

export const fetchDashboardReportApi = (filter, config = {}) => {
    let f = {
        ...filter,
        start: moment(filter.start).format('YYYY-MM-DD'),
        end: moment(filter.end).format('YYYY-MM-DD'),
        prefix: checkSubDomain()
    }
    return httpClient.get(`/report/dashboard?${httpObjQueryString(f)}`, config)
}


export const fetchReportProductApi = (filter, config = {}) => {
    let f = {
        start: filter.start,
        end: filter.end,
        modeUser: filter?.modeUser ?? '',
    }
    let c = {
        ...config,
        params: {
            catId: filter.catId,
            prefix: checkSubDomain()
        }
    }
    return httpClient.get(`/report/product?${httpObjQueryString(f)}`, c)
}