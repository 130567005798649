import {httpObjQueryString} from "../config/httpClient";
import {setDataParams, SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const fetchCategoryApi = (filter) => {
    return httpClient.get(`/category?${httpObjQueryString(filter)}`)
}

export const getCategoryApi = (id) => {
    return httpClient.get(`/category/${id}`)
}

export const newCategoryApi = (data) => {
    return httpClient.post(`/category`, setDataParams(data))
}

export const updateCategoryApi = (id, data) => {
    return httpClient.put(`/category/${id}`, setDataParams(data))
}

export const deleteCategoryApi = (id) => {
    return httpClient.delete(`/category/${id}`)
}
