import {BiDetail, BiPackage} from "react-icons/bi";
import {BsTruck, BsVectorPen} from "react-icons/bs";
import {MdCancel, MdCheck, MdOutlineReport, MdPayment, MdSend} from "react-icons/md";
import {AiOutlineException, AiOutlineRollback} from "react-icons/ai";

export const Status = [
    "Full", "Empty"
]

export const ContainerStatus = [
    {key: "in", name: "อยู่ในลาน"},
    {key: "out", name: "รับตู้เรียบร้อย"},
]

export const ContainerType = [
    "DC", "HQ", "Tank", "RF",
    "OT", "FR", "DG",
]

export const MonthTH = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
]

export const Roles = [
    {key: `1`, label: `ผู้ดูแลระบบ`},
    {key: `2`, label: `พนักงาน`},
    {key: `3`, label: `สมาชิก`},
    {key: `4`, label: `คนยิงแอด`},
    {key: `5`, label: `หัวหน้าแอดมิน`},
]


export const ExpenseType = {
    facebook: `Facebook`,
    shipping: `ค่าขนส่ง`,
    other: `อื่นๆ`,
}


export const OrderStatus = [
    {status: `pending`, name: `สินค้าจาก SalePage`},
    {status: `send`, name: `รอส่งสินค้า`},
    {status: `cancel`, name: `ยกเลิก`},
    {status: `sending`, name: `ส่งสินค้าแล้ว`},
    {status: `return`, name: `สินค้าตีกลับ(ริบสินค้าแล้ว)`},
    {status: `tracking`, name: `สถานะ`}
]

export const Provinces = [
    {"id": "1", "name": "กรุงเทพมหานคร", "nameEn": "Bangkok"},
    {"id": "2", "name": "สมุทรปราการ", "nameEn": "Samut Prakan"},
    {"id": "3", "name": "นนทบุรี", "nameEn": "Nonthaburi"},
    {"id": "4", "name": "ปทุมธานี", "nameEn": "Pathum Thani"},
    {"id": "5", "name": "พระนครศรีอยุธยา", "nameEn": "Phra Nakhon Si Ayutthaya"},
    {"id": "6", "name": "อ่างทอง", "nameEn": "Ang Thong"},
    {"id": "7", "name": "ลพบุรี", "nameEn": "Loburi"},
    {"id": "8", "name": "สิงห์บุรี", "nameEn": "Sing Buri"},
    {"id": "9", "name": "ชัยนาท", "nameEn": "Chai Nat"},
    {"id": "10", "name": "สระบุรี", "nameEn": "Saraburi"},
    {"id": "11", "name": "ชลบุรี", "nameEn": "Chon Buri"},
    {"id": "12", "name": "ระยอง", "nameEn": "Rayong"},
    {"id": "13", "name": "จันทบุรี", "nameEn": "Chanthaburi"},
    {"id": "14", "name": "ตราด", "nameEn": "Trat"},
    {"id": "15", "name": "ฉะเชิงเทรา", "nameEn": "Chachoengsao"},
    {"id": "16", "name": "ปราจีนบุรี", "nameEn": "Prachin Buri"},
    {"id": "17", "name": "นครนายก", "nameEn": "Nakhon Nayok"},
    {"id": "18", "name": "สระแก้ว", "nameEn": "Sa Kaeo"},
    {"id": "19", "name": "นครราชสีมา", "nameEn": "Nakhon Ratchasima"},
    {"id": "20", "name": "บุรีรัมย์", "nameEn": "Buri Ram"},
    {"id": "21", "name": "สุรินทร์", "nameEn": "Surin"},
    {"id": "22", "name": "ศรีสะเกษ", "nameEn": "Si Sa Ket"},
    {"id": "23", "name": "อุบลราชธานี", "nameEn": "Ubon Ratchathani"},
    {"id": "24", "name": "ยโสธร", "nameEn": "Yasothon"},
    {"id": "25", "name": "ชัยภูมิ", "nameEn": "Chaiyaphum"},
    {"id": "26", "name": "อำนาจเจริญ", "nameEn": "Amnat Charoen"},
    {"id": "27", "name": "หนองบัวลำภู", "nameEn": "Nong Bua Lam Phu"},
    {"id": "28", "name": "ขอนแก่น", "nameEn": "Khon Kaen"},
    {"id": "29", "name": "อุดรธานี", "nameEn": "Udon Thani"},
    {"id": "30", "name": "เลย", "nameEn": "Loei"},
    {"id": "31", "name": "หนองคาย", "nameEn": "Nong Khai"},
    {"id": "32", "name": "มหาสารคาม", "nameEn": "Maha Sarakham"},
    {"id": "33", "name": "ร้อยเอ็ด", "nameEn": "Roi Et"},
    {"id": "34", "name": "กาฬสินธุ์", "nameEn": "Kalasin"},
    {"id": "35", "name": "สกลนคร", "nameEn": "Sakon Nakhon"},
    {"id": "36", "name": "นครพนม", "nameEn": "Nakhon Phanom"},
    {"id": "37", "name": "มุกดาหาร", "nameEn": "Mukdahan"},
    {"id": "38", "name": "เชียงใหม่", "nameEn": "Chiang Mai"},
    {"id": "39", "name": "ลำพูน", "nameEn": "Lamphun"},
    {"id": "40", "name": "ลำปาง", "nameEn": "Lampang"},
    {"id": "41", "name": "อุตรดิตถ์", "nameEn": "Uttaradit"},
    {"id": "42", "name": "แพร่", "nameEn": "Phrae"},
    {"id": "43", "name": "น่าน", "nameEn": "Nan"},
    {"id": "44", "name": "พะเยา", "nameEn": "Phayao"},
    {"id": "45", "name": "เชียงราย", "nameEn": "Chiang Rai"},
    {"id": "46", "name": "แม่ฮ่องสอน", "nameEn": "Mae Hong Son"},
    {"id": "47", "name": "นครสวรรค์", "nameEn": "Nakhon Sawan"},
    {"id": "48", "name": "อุทัยธานี", "nameEn": "Uthai Thani"},
    {"id": "49", "name": "กำแพงเพชร", "nameEn": "Kamphaeng Phet"},
    {"id": "50", "name": "ตาก", "nameEn": "Tak"},
    {"id": "51", "name": "สุโขทัย", "nameEn": "Sukhothai"},
    {"id": "52", "name": "พิษณุโลก", "nameEn": "Phitsanulok"},
    {"id": "53", "name": "พิจิตร", "nameEn": "Phichit"},
    {"id": "54", "name": "เพชรบูรณ์", "nameEn": "Phetchabun"},
    {"id": "55", "name": "ราชบุรี", "nameEn": "Ratchaburi"},
    {"id": "56", "name": "กาญจนบุรี", "nameEn": "Kanchanaburi"},
    {"id": "57", "name": "สุพรรณบุรี", "nameEn": "Suphan Buri"},
    {"id": "58", "name": "นครปฐม", "nameEn": "Nakhon Pathom"},
    {"id": "59", "name": "สมุทรสาคร", "nameEn": "Samut Sakhon"},
    {"id": "60", "name": "สมุทรสงคราม", "nameEn": "Samut Songkhram"},
    {"id": "61", "name": "เพชรบุรี", "nameEn": "Phetchaburi"},
    {"id": "62", "name": "ประจวบคีรีขันธ์", "nameEn": "Prachuap Khiri Khan"},
    {"id": "63", "name": "นครศรีธรรมราช", "nameEn": "Nakhon Si Thammarat"},
    {"id": "64", "name": "กระบี่", "nameEn": "Krabi"},
    {"id": "65", "name": "พังงา", "nameEn": "Phangnga"},
    {"id": "66", "name": "ภูเก็ต", "nameEn": "Phuket"},
    {"id": "67", "name": "สุราษฎร์ธานี", "nameEn": "Surat Thani"},
    {"id": "68", "name": "ระนอง", "nameEn": "Ranong"},
    {"id": "69", "name": "ชุมพร", "nameEn": "Chumphon"},
    {"id": "70", "name": "สงขลา", "nameEn": "Songkhla"},
    {"id": "71", "name": "สตูล", "nameEn": "Satun"},
    {"id": "72", "name": "ตรัง", "nameEn": "Trang"},
    {"id": "73", "name": "พัทลุง", "nameEn": "Phatthalung"},
    {"id": "74", "name": "ปัตตานี", "nameEn": "Pattani"},
    {"id": "75", "name": "ยะลา", "nameEn": "Yala"},
    {"id": "76", "name": "นราธิวาส", "nameEn": "Narathiwat"},
    {"id": "77", "name": "บึงกาฬ", "nameEn": "buogkan"}
]


export const FlashShippingType = [
    {code: 1, name: `ธรรมดา`},
    {code: 2, name: `บริการ Speed`},
    {code: 4, name: `ราคาพิเศษสำหรับพัสดุขนาดใหญ่`},
]

export const FlashCategoryType = [
    {code: 0, name: `เอกสาร`},
    {code: 1, name: `อาหารแห้ง`},
    {code: 2, name: `ของใช้`},
    {code: 3, name: `อุปกรณ์ไอที`},
    {code: 4, name: `เสื้อผ้า`},
    {code: 5, name: `สื่อบันเทิง`},
    {code: 6, name: `อะไหล่รถยนต์`},
    {code: 7, name: `รองเท้า/กระเป๋า`},
    {code: 8, name: `อุปกรณ์กีฬา`},
    {code: 9, name: `เครื่องสำอางค์`},
    {code: 10, name: `เฟอร์นิเจอร์`},
    {code: 11, name: `ผลไม้`},
    {code: 99, name: `อื่นๆ`},
]

export const FlashSignCategory = [
    {code: 0, name: `เจ้าของสินค้า`},
    {code: 1, name: `เพื่อนบ้าน`},
    {code: 2, name: `เพื่อนร่วมงาน`},
    {code: 3, name: `รปภ.`},
    {code: 4, name: `แผนกต้อนรับ`},
    {code: 5, name: `ครอบครัว`},
]

export const FlashTrackingStatus = [
    {code: 1, name: `รับพัสดุแล้ว`, c: `pickedUp`, icon: <BiPackage/>},
    {code: 2, name: `ระหว่างการขนส่ง`, c: `inTransit`, icon: <BsTruck/>},
    {code: 3, name: `ระหว่างการจัดส่ง`, c: `delivering`, icon: <BsTruck/>},
    {code: 4, name: `พัสดุคงคลัง`, c: `detained`, icon: <BiDetail/>},
    {code: 5, name: `เซ็นรับแล้ว`, c: `signed`, icon: <BsVectorPen/>},
    {code: 6, name: `ระหว่างจัดการพัสดุมีปัญหา`, c: `problem`, icon: <MdOutlineReport/>},
    {code: 7, name: `พัสดุตีกลับแล้ว`, c: `returned`, icon: <AiOutlineRollback/>},
    {code: 5, name: `พัสดุตีกลับเซ็นรับแล้ว`, c: `returnedSuccess`, icon: <AiOutlineRollback/>},
    {code: 8, name: `ปิดงานมีปัญหา`, c: `exception`, icon: <AiOutlineException/>},
    {code: 9, name: `เรียกคืนพัสดุแล้ว`, c: `cancelled`, icon: <MdCancel/>},
]
export const JntTrackingStatus = [
    // {code: 0, name: `คำสั่ง`, c: `createOrder`, icon: <BiPackage/>},
    {code: 1, name: `รับพัสดุแล้ว`, c: `pickUp`, icon: <BiPackage/>},
    {code: 2, name: `จัดสั่งพัสดุ`, c: `departure`, icon: <BsTruck/>},
    {code: 3, name: `มาถึง`, c: `arrival`, icon: <BsTruck/>},
    {code: 4, name: `นำจ่ายพัสดุ`, c: `delivery`, icon: <BiDetail/>},
    {code: 5, name: `รับพัสดุด้วยตนเอง`, c: `selfCollection`, icon: <BsVectorPen/>},
    {code: 6, name: `เซ็นรับพัสดุ`, c: `signature`, icon: <MdOutlineReport/>},
    {code: 7, name: `ตีกลับพัสดุ`, c: `return`, icon: <AiOutlineRollback/>},
    {code: 8, name: `ยืนยันตีกลับพัสดุ`, c: `returnConfirmation`, icon: <AiOutlineException/>},
    {code: 9, name: `เซ็นรับพัสดุตีกลับ`, c: `returnSignature`, icon: <MdCancel/>},
    {code: 10, name: `พัสดุมีปัญหา`, c: `problematic`, icon: <MdCancel/>},
    {code: 11, name: `พัสดุตกค้าง`, c: `overnight`, icon: <MdCancel/>},
    {code: 99, name: `สร้าง ORDER`, c: `createOrder`, icon: <BiPackage/>},
]

export const KerryTrackingStatus = [
    // {code: 0, name: `ส่งพีสดุท`, c: `createOrder`, icon: <BiPackage/>},
    {code: 99, name: `สร้าง ORDER`, c: `CreateOrder`, icon: <BiPackage/>},
    {code: 13, name: `พร้อมส่งให้ Kerry`, c: `Ready`, icon: <BiPackage/>},
    {code: 98, name: `ผู้ส่งนำส่งที่สาขา`, c: `Received`, icon: <BiPackage/>},
    {code: 1, name: `รับพัสดุแล้ว`, c: `PickedUp`, icon: <BiPackage/>},
    {code: 97, name: `รับพัสดุโดย Courier`, c: `CourierPickedUp`, icon: <BiPackage/>},
    {code: 96, name: `เตรียมนำส่ง`, c: `Prepared`, icon: <BiPackage/>},
    {code: 2, name: `จัดสั่งพัสดุ`, c: `Delivering`, icon: <BsTruck/>},
    {code: 3, name: `รอส่งใหม่อีกครั้ง`, c: `PendingReDelivering`, icon: <BsTruck/>},
    {code: 4, name: `ส่งไปยังที่อยู่ใหม่`, c: `NewAddress`, icon: <BiDetail/>},
    {code: 5, name: `กำลังตีกลับไปต้นทาง`, c: `Returning`, icon: <BsTruck/>},
    {code: 6, name: `ถึงสถานีปลายทาง`, c: `ArrivedDelivering`, icon: <BsTruck/>},
    {code: 7, name: `ตีกลับพัสดุต้นทาง`, c: `SuccessReturned`, icon: <BsTruck/>},
    {code: 8, name: `เตรียมขนส่งระหว่างประเทศ`, c: `InTransitInt`, icon: <BsTruck/>},
    {code: 9, name: `ระหว่างขนส่งระหว่างประเทศ`, c: `InTransitIntBorder`, icon: <BsTruck/>},
    {code: 10, name: `ดำเนินเรื่องศุลกากร`, c: `InTransitIntCustoms`, icon: <BsTruck/>},
    {code: 12, name: `ถึงจุดรับพัศดุแล้ว`, c: `ReadyToCollect`, icon: <BsTruck/>},
    {code: 14, name: `กำลังจัดส่ง`, c: `InTransit`, icon: <BsTruck/>},
    {code: 15, name: `จัดส่งไม่สำเร็จ`, c: `UnSuccessDelivering`, icon: <MdCancel/>},
    {code: 16, name: `Closed on arrival`, c: `ClosedDelivering`, icon: <BsTruck/>},
    {code: 17, name: `Awaiting consignee collection`, c: `AwaitConsigneeDelivering`, icon: <BsTruck/>},
    {code: 18, name: `Customer moved`, c: `CustomerMoved`, icon: <BsTruck/>},
    {code: 19, name: `ปลายทางรับพัสดุบางส่วน`, c: `PartiallyDelivering`, icon: <BsTruck/>},
    {code: 11, name: `จัดส่งสำเร็จ`, c: `SuccessDelivered`, icon: <MdCheck/>},

]

export const ThaiPostTrackingStatus = [
    // {code: 0, name: `คำสั่ง`, c: `createOrder`, icon: <BiPackage/>},
    {code: 99, name: `สร้าง ORDER`, c: `CreateOrder`, icon: <BiPackage/>},
    {code: 101, name: `เตรียมการฝากส่ง`, c: `PickUp`, icon: <BiPackage/>},
    {code: 102, name: `รับฝากผ่านตัวแทน`, c: `AgentDeposit`, icon: <BiPackage/>},
    {code: 103, name: `รับฝาก`, c: `Deposit`, icon: <BiPackage/>},
    {code: 104, name: `ผู้ฝากส่งขอถอนคืน / ยกเลิกการรับฝาก`, c: `Cancel`, icon: <MdCancel/>},
    {code: 201, name: `อยู่ระหว่างการขนส่ง`, c: `Delivering`, icon: <BsTruck/>},
    {code: 202, name: `ดำเนินพิธีการศุลกากร`, c: `InTransitIntCustoms`, icon: <BsTruck/>},
    {code: 203, name: `ส่งคืนต้นทาง`, c: `Return`, icon: <BsTruck/>},
    {code: 204, name: `ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก`, c: `InTransitIntBorder`, icon: <BsTruck/>},
    {code: 205, name: `ถึงที่ทำการแลกเปลี่ยนระหว่างประเทศขาเข้า`, c: `ArriveTransitIntBorder`, icon: <BsTruck/>},
    {code: 206, name: `ถึงที่ทำการไปรษณีย์`, c: `ArriveOffice`, icon: <BsTruck/>},
    {code: 208, name: `ส่งออกจากที่ทำการแลกเปลี่ยนระหว่างประเทศขาออก`, c: `OutTransitIntBorder`, icon: <BsTruck/>},
    {code: 209, name: `ยกเลิกการส่งออก`, c: `CancelOut`, icon: <BsTruck/>},
    {code: 210, name: `ยกเลิกการนำเข้า`, c: `CancelIn`, icon: <BsTruck/>},
    {code: 211, name: `รับเข้า ณ ศูนย์คัดแยก`, c: `ReadyToCollect`, icon: <BsTruck/>},
    {code: 212, name: `ส่งมอบให้สายการบิน`, c: `AirPlane`, icon: <MdSend/>},
    {code: 213, name: `สายการบินรับมอบ`, c: `InAirPlane`, icon: <MdSend/>},
    {code: 301, name: `อยู่ระหว่างการนำจ่าย`, c: `Delivery`, icon: <BsTruck/>},
    {code: 302, name: `อยู่ระหว่างการนำจ่ายนำจ่าย ณ จุดรับสิ่งของ`, c: `InDelivery`, icon: <BsTruck/>},
    {code: 401, name: `นำจ่ายไม่สำเร็จ`, c: `UnsuccessDelivery`, icon: <BsTruck/>},
    {code: 501, name: `นำจ่ายสำเร็จ`, c: `SuccessDelivery`, icon: <BsTruck/>},
    {code: 501, name: `พัสดุตีกลับเซ็นรับแล้ว`, c: `returnedSuccess`, icon: <AiOutlineRollback/>},
    {code: 901, name: `โอนเงินให้ผู้ขายเรียบร้อยแล้ว`, c: `Payment`, icon: <MdPayment/>},
]


export const JntErrorsCode = {
    S01: `รูปแบบ JSON ไม่ถูกต้อง`,
    S02: `Illegal digital signature`,
    S03: `Illegal customer company number`,
    S04: `Illegal notification type`,
    S05: `Illegal notification content`,
    S06: `Connection timed out`,
    S07: `The system is abnormal, please try again`,
    S08: `Illegal e-commerce platform`,
    S09: `No task data back`,
    S10: `Duplicate order number`,
    S11: `repeating waybill number`,
    S12: `Order status is GOT can not cancel order`,
    S13: `Customer information does not exist`,
    S15: `Order does not exist`,
    S16: `The waybill no does not exist`,
    S17: `Database execution exception`,
    S18: `The customer number does not exist in the shop table`,

    B002: `Customerid cannot be empty`,
    B003: `Txlogisticid cannot be empty`,
    B004: `Ordertype cannot be empty`,
    B005: `Servicetype cannot be empty`,
    B006: `The sender name sender.name cannot be empty`,
    B008: `Send phone sender.mobile can not be empty`,
    B010: `Sender.sender.city cannot be empty`,
    B011: `Shipment county sender.area can not be empty`,
    B012: `寄件地址sender.address不能为空**`,
    B013: `The recipient name receiver.name cannot be empty`,
    B014: `Receiving mobile phone receiver.mobile can not be empty`,
    B016: `Receiver receiver.city cannot be empty`,
    B017: `The recipient area county.area cannot be empty`,
    B018: `收件地址receiver.address不能为空**`,
    B019: `Order creation time createordertime cannot be empty`,
    B020: `Home pick-up start time sendstarttime cannot be empty`,
    B021: `Door picking end time sendendtime cannot be empty`,
    B022: `Payment method paytype cannot be empty`,
    B023: `Total number of totalquantity cannot be empty`,
    B024: `The collection value of the item value cannot be empty`,
    B025: `Chinese name items.itemname can not be empty`,
    B026: `English name items.englishName cannot be empty`,
    B027: `number of items.number cannot be empty`,
    B028: `Declared value (IDR) items.itemvalue cannot be empty`,
    B029: `Item URL items.itemurl cannot be empty`,
    B030: `Sender.sender.city does not exist`,
    B031: `Sender area county sender.area does not exist`,
    B032: `The recipient area county.area does not exist`,
    B033: `Receive postcode receiver.postcode error`,
    B034: `Recipient's receiver.city error`,
    B035: `Items error`,
    B060: `order operation type cannot be empty`,
    B062: `ที่อยู่ไม่ถูกต้อง`,
    B063: `ชื่ออำเภอหรือจังหวัดปลายทางไม่ถูกต้อง ให้ลองลบแล้วพิมพ์ ใหม่อีกครั้ง`,
    B064: `Weight cannot be empty`,
    B065: `Order type operation is illegal`,
    B066: `order number cannot be empty`,
    B067: `The waybill number cannot be empty`,
    B068: `Operational waybill does not match current e-commerce`,
    B069: `Cancel order failed, record not found`,
    B070: `save failed`,
    B071: `Employee number does not exist`,
    B072: `Order file creation failed`,
    B073: `Remark longer than 400`,
    B074: `weight over 50kg`,
    B075: `Incorrect length, width and height`,
    B076: `One side cannot exceed 100 CM`,
    B077: `COD exceeds the maximum limit`,
    B078: `Shipping information cannot be empty`,
    B079: `Receiving information cannot be empty`,
    B080: `The face sheet type cannot be empty`,
    B081: `Registration information already exists`,
    B083: `Customer ID is too long`,
    B084: `Customer information does not exist`,
    B085: `registration failed`,
    B086: `The outlet information does not exist`,
    B087: `Customer quote is not maintained`,
    B088: `customerId cannot be empty`,
    B089: `name cannot be empty`,
    B090: `phone cannot be empty`,
    B091: `address cannot be empty`,
    B092: `postCode cannot be empty`,
    B093: `source cannot be empty`,
    B094: `The format of the shipping zip code is incorrect`,
    B095: `The receiving postal code format is incorrect`,
    B096: `The format of sendstarttime is incorrect`,
    B097: `The format of sendendtime is incorrect`,
    B098: `The format of the order creation time createordertime is incorrect`,
    B101: `Sender number (landline) senderPhone is incorrect`,
    B102: `Sender's phone number senderMobile is in incorrect format`,
    B103: `Recipient phone number (landline) The format of receiverPhone is incorrect`,
    B104: `The recipient mobile phone number receiverMobile is incorrect`,
}
