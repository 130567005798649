import {createSlice} from "@reduxjs/toolkit";
import {
    fetchAsyncOrder,
    getAsyncOrder,
    getAsyncOrderMessage,
    openAsyncOrder,
    openAsyncOrderImport,
    printAsyncOrderReceipt
} from "./orderthunk";

const initialState = {
    items: {
        data: [],
    },
    item: {},
    openOrder: {message: ``},
    orderProduct: [],
    orderCategory: [],
    itemsMessage: {
        data: [],
    },
    importOrder: [],
    receiptUrl: ``,
}

const orderSlice = createSlice({
    name: `order`,
    initialState: initialState,
    reducers: {
        setOpenOrder: (state, {payload}) => {
            return {...state, openOrder: {...state.openOrder, [payload.name]: payload.value}}
        },
        setReceiptUrl: (state, {payload}) => {
            return {...state, receiptUrl: payload}
        },

    },
    extraReducers: {
        [fetchAsyncOrder.fulfilled]: (state, {payload}) => {
            let products = [], category = []
            if (typeof payload?.data !== `undefined`) {
                payload.data.map(order => {
                    order.items.map(item => {
                        const index = products.findIndex(i => i.id == item.products.id)
                        if (index == -1) {
                            products.push({
                                id: item.products.id,
                                name: item.products.name,
                                order: 1,
                                unit: item.unit,
                                orderId: [order.id],
                            })
                        } else {
                            products[index].order += 1
                            products[index].unit += item.unit
                            products[index].orderId.push(order.id)
                            products[index].orderId = products[index].orderId.filter((value, index, self) => {
                                return self.indexOf(value) === index
                            })
                        }
                        const indexC = category.findIndex(i => i.id == item.category.id)
                        let unit = item.unit
                        // console.log(`${item.unit}`)
                        if (indexC == -1) {
                            category.push({
                                id: item.category?.id ?? 0,
                                name: item.category?.name ?? '-',
                                order: 1,
                                unit: unit,
                                orderId: [order.id],
                            })
                        } else {
                            category[indexC].order += 1
                            category[indexC].unit += parseInt(unit)
                            category[indexC].orderId.push(order.id)
                            category[indexC].orderId = category[indexC].orderId.filter((value, index, self) => {
                                return self.indexOf(value) === index
                            })
                        }
                    })
                })
                // console.log(category)
            }
            return {
                ...state,
                items: payload,
                orderProduct: products,
                orderCategory: category,
            }
        },
        [getAsyncOrder.fulfilled]: (state, {payload}) => {
            let data = payload
            if (typeof data?.webhook !== `undefined` && data?.webhook?.length > 0) {
                // data.webhook.body = JSON.parse(data?.webhook?.body)
                data?.webhook?.map((v, i) => {
                    data.webhook[i].body = JSON.parse(v?.body)
                })
            }
            // console.log(data.webhook)
            return {
                ...state,
                item: data
            }
        },
        [openAsyncOrder.fulfilled]: (state, {payload}) => {
            if (typeof payload !== `undefined`) {
                return {
                    ...state,
                    openOrder: {message: ``}
                }
            }
        },
        [openAsyncOrderImport.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                importOrder: payload
            }
        },
        [getAsyncOrderMessage.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                itemsMessage: payload
            }
        },

        [printAsyncOrderReceipt.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                receiptUrl: payload
            }
        },
    },
})
export const getOrders = (state) => state.order.items
export const getOrder = (state) => state.order.item
export const getOrderProducts = (state) => state.order.orderProduct
export const getOrderCategory = (state) => state.order.orderCategory
export const openOrder = (state) => state.order.openOrder
export const itemsMessage = (state) => state.order.itemsMessage
export const importOrder = (state) => state.order.importOrder
export const receiptUrl = (state) => state.order.receiptUrl

export const {
    setOpenOrder,
    setReceiptUrl,
} = orderSlice.actions

export default orderSlice.reducer
