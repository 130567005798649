import {createSlice} from "@reduxjs/toolkit";
import {printAsyncLaosLabel} from "./laosThunk";

const initialState = {
    selectedItems: [], pdf: {
        url: ``
    },
}

const laosSlice = createSlice({
    name: `jnt`, initialState: initialState, reducers: {
        setSelectedOrderItems: (state, {payload}) => {
            return {
                ...state, selectedItems: payload
            }
        }, setPdfUrl: (state, {payload}) => {
            return {...state, pdf: {url: payload}}
        },
    }, extraReducers: {
        [printAsyncLaosLabel.fulfilled]: (state, {payload}) => {
            return {
                ...state, pdf: payload
            }
        },
    }
})
export const getPdfUrl = (state) => state.laos.pdf
export const getSelectedOrderItems = (state) => state.laos.selectedItems
export const laosState = (state) => state.laos
export const {setSelectedOrderItems, setPdfUrl} = laosSlice.actions
export default laosSlice.reducer
