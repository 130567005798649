import {createSlice} from "@reduxjs/toolkit";
import {
    createAsyncFlashExpressOrder,
    fetchAsyncCommission, fetchAsyncReportDashboard,
    fetchAsyncReportMonthly, fetchAsyncReportProduct,
    fetchAsyncReportSipping, printAsyncFlashTrackingOrder
} from "./reportThunk";

const initialState = {
    monthly: {
        items: [],
        total: {},
    },
    daily: [],
    comission: [],
    shipping: [],
    pdfPrint: '',
    dashboard: {},
    products: [],
}

const reportSlice = createSlice({
    name: `report`,
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [fetchAsyncReportMonthly.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                monthly: payload
            }
        },
        [fetchAsyncReportProduct.fulfilled]: (state, {payload}) => {
            console.log(payload)
            return {
                ...state,
                products: payload
            }
        },
        [fetchAsyncCommission.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                comission: payload
            }
        },
        [fetchAsyncReportSipping.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                shipping: payload
            }
        },
        [fetchAsyncReportDashboard.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                dashboard: payload
            }
        },
    },
})
export const getReportMonthly = (state) => state.report.monthly
export const getReportProduct = (state) => state.report.products
export const getReportComission = (state) => state.report.comission
export const getReportShipping = (state) => state.report.shipping
export const getPdf = (state) => state.report.pdfPrint
export const getDashboard = (state) => state.report.dashboard

export default reportSlice.reducer
