import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import moment from "moment";
import axios from "axios";

const initialState = {
    loading: 'WAIT',
    redirect: false,
    activeMenu: null,
    activeSubMenu: null,
    modal: false,
    modalContent: {
        title: ``,
        size: `lg`,
        content: ``
    },
    popupConfirm: false,
    progressDialog: false,
    progressDialogMessage: ``,
    clickMenu: null,
    sideBarOpen: true,
    submit: 0,
    anoucement: ``,
    orderDuplcate: null,
}

export const anouceAync = createAsyncThunk('utility/anouceAync', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await axios.get(`/anoucement/info.json`)
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})


const utilitySlice = createSlice({
    name: `utility`,
    initialState: initialState,
    reducers: {
        setSiderbar: (state, {payload}) => {
            return {...state, sideBarOpen: payload}
        },
        setLoading: (state, {payload}) => {
            return {...state, loading: payload.loading}
        },
        setRedirect: (state, {payload}) => {
            return {...state, redirect: payload}
        },
        setActiveMenu: (state, {payload}) => {
            if (typeof payload.hasChilds === `undefined`)
                return {...state, activeMenu: payload, clickMenu: moment().unix(), activeSubMenu: null}

            return {...state, activeMenu: payload.status, activeSubMenu: payload.hasChilds}
        },
        setModal: (state, {payload}) => {
            return {...state, modal: payload}
        },
        setPopupConfirm: (state, {payload}) => {
            return {...state, popupConfirm: payload}
        },
        setOpenModalContent: (state, {payload}) => {
            return {...state, modal: true, modalContent: payload}
        },
        setOpenModalTitle: (state, {payload}) => {
            return {...state, modal: true, modalContent: {...state.modalContent, title: payload}}
        },
        setProgressDialog: (state, {payload}) => {
            if (typeof payload?.status !== `undefined`) {
                return {...state, progressDialog: payload.status, progressDialogMessage: payload?.message ?? ''}
            } else {
                return {...state, progressDialog: payload, progressDialogMessage: ``}
            }
        },
        setSubmit: (state, {payload}) => {
            return {...state, submit: payload}
        },
        setOrderDuplicate: (state, {payload}) => {
            console.log(payload)
            return {...state, orderDuplcate: payload}
        },
    },
    extraReducers: {
        [anouceAync.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                anoucement: payload
            }
        }
    }
})

export const loading = (state) => state.utility.loading
export const redirect = (state) => state.utility.redirect
export const activeMenu = (state) => state.utility.activeMenu
export const activeSubMenu = (state) => state.utility.activeSubMenu
export const myModal = (state) => state.utility.modalContent
export const modal = (state) => state.utility.modal
export const popupConfirm = (state) => state.utility.popupConfirm
export const progressDialog = (state) => state.utility.progressDialog
export const progressDialogMessage = (state) => state.utility.progressDialogMessage
export const getClickMenu = (state) => state.utility.clickMenu
export const sideBarOpen = (state) => state.utility.sideBarOpen
export const getSubmit = (state) => state.utility.submit
export const getAnoucement = (state) => state.utility.anoucement
export const getOrderDuplicate = (state) => state.utility.orderDuplcate

export const {
    setLoading,
    setRedirect,
    setActiveMenu,
    setModal,
    setOpenModalContent,
    setOpenModalTitle,
    setPopupConfirm,
    setProgressDialog,
    setSiderbar,
    setSubmit,
    setOrderDuplicate,
} = utilitySlice.actions

export default utilitySlice.reducer



