import {createAsyncThunk} from "@reduxjs/toolkit";
import {setLoading, setProgressDialog} from "../utility";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {addSettingApi, getAllSettingApi} from "../../../common/api/settingApi";
import {notificationSuccessMessage} from "../../../common/config/message";

export const getAsyncSetting = createAsyncThunk('setting/getAsyncSetting', async (keys, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, getAllSettingApi(keys))
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
    }
})
export const addAsyncSetting = createAsyncThunk('setting/addAsyncSetting', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, addSettingApi(data))
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
    }
})


export const exportExcel = createAsyncThunk('setting/exportExcel', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    // baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, data.callback(data.params))
        baseThunk.dispatch(setProgressDialog(true))
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        // baseThunk.dispatch(setLoading({loading: 'ERROR'}))
    }
})
