import {createSlice} from "@reduxjs/toolkit";
import {
    cancelAsyncFlashExpressOrder,
    createAsyncFlashExpressOrder,
    getAsyncFlashCourier,
    getAsyncFlashExpressTracking, getAsyncFlashKACodBills,
    getAsyncFlashKACodDayTotal, getAsyncFlashKACodParcels, getAsyncFlashKACodPayment, getAsyncFlashKAPod,
    getAsyncFlashNotifications,
    getAsyncFlashTrackingPrint,
    getAsyncFlashWarehouse,
    printAsyncFlashTrackingOrder
} from "./flashExpressThunk";
import localStorageService from "../../../services/localStorageService";

const localStorage = localStorageService.getService()
const initialState = {
    tracking: {},
    selectedItems: [],
    pdf: {
        url: ``
    },
    warehouse: {
        items: [],
    },
    notifications: [],
    printHistory: {
        data: [],
    },
    couriers: {
        data: {items: [], pagination: {current_page: 1, per_page: 20, total_count: 0}}
    },
    kaCodDay: [],
    kaCodBills: {
        items: [],
        pagination: {},
    },
    kaParcels: {},
    kaPayment: {},
    kaPod: {},
}

const flashExpressSlice = createSlice({
    name: `flashExpress`,
    initialState: initialState,
    reducers: {
        setSelectedOrderItems: (state, {payload}) => {
            return {
                ...state,
                selectedItems: payload
            }
        },
        setPdfUrl: (state, {payload}) => {
            return {
                ...state,
                pdf: {
                    url: payload
                }
            }
        },
    },
    extraReducers: {
        [getAsyncFlashExpressTracking.fulfilled]: (state, {payload}) => {
            return {...state, tracking: payload}
        },
        [createAsyncFlashExpressOrder.fulfilled]: (state, {payload}) => {
            const config = localStorage.getConfig()
            if (typeof payload !== `undefined` && payload.length > 0) {
                return {
                    ...state,
                    selectedItems: state.selectedItems.map(order => {
                        let o = order
                        payload.map(p => {
                            let Id = p.id
                            Id = parseInt(Id.replaceAll(`${config?.prefix ?? ''}-`, ``))
                            // console.log(Id)
                            if (Id == order.id) {
                                if (p.message === "success") {
                                    o = {...order, pno: p.data.pno, t: `success`, error: null, errMessage: null}
                                } else {
                                    o = {...order, pno: '', t: 'error', error: p.data, errMessage: p.message}
                                }
                            }
                        })
                        return o
                    })
                }
            }
        },
        [cancelAsyncFlashExpressOrder.fulfilled]: (state, {payload}) => {
            const config = localStorage.getConfig()
            return {
                ...state,
                selectedItems: state.selectedItems.map(order => {
                    let o = order
                    payload.map(p => {

                        let Id = p.id
                        // console.log(`old : ${Id}`)
                        Id = `${Id}`
                        Id = parseInt(Id.replace(`${config?.prefix ?? ''}-`, ``))

                        console.log(`new : ${Id}`)
                        if (Id == order.id) {
                            if (p.pno === "") {
                                o = {...p, cancelTracking: "success", cancelMessage: p?.message ?? ``}
                            } else {
                                o = {...p, cancelTracking: "error", cancelMessage: p?.message ?? ``}
                            }
                        }
                    })
                    return o
                })
            }
        },
        [printAsyncFlashTrackingOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                pdf: payload
            }
        },
        [getAsyncFlashWarehouse.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                warehouse: payload
            }
        },
        [getAsyncFlashNotifications.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                notifications: payload
            }
        },
        [getAsyncFlashCourier.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                couriers: payload
            }
        },
        [getAsyncFlashTrackingPrint.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                printHistory: payload
            }
        },
        [getAsyncFlashKACodDayTotal.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                kaCodDay: payload?.data
            }
        },
        [getAsyncFlashKACodBills.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                kaCodBills: payload?.data
            }
        },
        [getAsyncFlashKACodParcels.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                kaParcels: payload?.data
            }
        },
        [getAsyncFlashKACodPayment.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                kaPayment: payload?.data
            }
        },
        [getAsyncFlashKAPod.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                kaPod: payload?.data
            }
        },
    },
})

export const getTracking = (state) => state.flashExpress.tracking
export const getSelectedOrderItems = (state) => state.flashExpress.selectedItems
export const getPdfUrl = (state) => state.flashExpress.pdf
export const getFlashWarehouse = (state) => state.flashExpress.warehouse
export const getNotifications = (state) => state.flashExpress.notifications
export const getCouriers = (state) => state.flashExpress.couriers
export const getPrintFlashHistory = (state) => state.flashExpress.printHistory
export const getKaCodDay = (state) => state.flashExpress.kaCodDay
export const getKaCodBills = (state) => state.flashExpress.kaCodBills
export const getKaCoParcels = (state) => state.flashExpress.kaParcels
export const getKaCoPayment = (state) => state.flashExpress.kaPayment
export const getKaPod = (state) => state.flashExpress.kaPod

export const {setSelectedOrderItems, setPdfUrl} = flashExpressSlice.actions

export default flashExpressSlice.reducer
