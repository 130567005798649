import {createSlice} from "@reduxjs/toolkit";
import {fetchAsyncProduct, fetchAsyncProductSearch, fetchAsyncProductStock} from "./productThunk";


const initialState = {
    items: {
        data: [],
    },
    itemsStock: {
        data: [],
    },
    showModal: false,
    itemsSearch: {
        data: [],
    },
}


const productSlice = createSlice({
    name: `product`,
    initialState: initialState,
    reducers: {
        setProductShowModal: (state, {payload}) => {
            return {...state, showModal: payload}
        },
        setProducts: (state, {payload}) => {
            return {
                ...state, items: {
                    ...state.items,
                    data: payload
                }
            }
        },
        setProductItem: (state, {payload}) => {
            return {
                ...state, items: {
                    ...state.items,
                    data: state.items.data.map(item => item.id == payload.id ? {...item, isCheck: true} : item)
                }
            }
        },
    },
    extraReducers: {
        [fetchAsyncProduct.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                items: payload
            }
        },
        [fetchAsyncProductStock.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                itemsStock: payload
            }
        },
        [fetchAsyncProductSearch.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                itemsSearch: payload
            }
        }
    },
})


export const getProducts = (state) => state.product.items
export const getProductStock = (state) => state.product.itemsStock
export const getProductsSearch = (state) => state.product.itemsSearch
export const showProductModal = (state) => state.product.showModal
export const {setProductShowModal, setProducts, setProductItem} = productSlice.actions
export default productSlice.reducer
