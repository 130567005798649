import {getSubDomain} from "../functions/usage";
import httpClient, {httpClientForm} from "../config/httpClient";

export const SetGateWay = () => {
    let subdomain = checkSubDomain(getSubDomain())
    if (subdomain == `main`) {
        if (process.env.REACT_APP_ENV !== `local`) {
            subdomain = `/v1`
        } else {
            subdomain = ``
        }
    } else {
        subdomain = `/${subdomain}`
    }

    if (process.env.REACT_APP_ENV === `local`) {
        subdomain = ``
    }

    const baseUrl = `${process.env.REACT_APP_API_BASE}${subdomain}`
    httpClient.defaults.baseURL = baseUrl
    httpClientForm.defaults.baseURL = baseUrl

    return {
        httpGateWay: httpClient,
        httpGateWayForm: httpClientForm
    }
}

export const checkSubDomain = (sub = '') => {
    let domain = sub
    if (domain === ``) {
        domain = getSubDomain()
    }
    if (typeof domain === `undefined` || domain === `test` || domain === `` || domain === `localhost:3000` || domain === `systemtong` || domain.includes(`localhost`) || domain.includes(`127.0.0.1`)) {
        domain = `tong`
    }

    return domain
}

export const setDataParams = data => {
    return {...data, prefix: checkSubDomain()}
}
