import {createSlice} from "@reduxjs/toolkit";
import {fetchAsyncExpense} from "./expenseThunk";



const initialState = {
    items: {
        data: [],
    },
    showModal: false
}


const expenseSlice = createSlice({
    name: `expense`,
    initialState: initialState,
    reducers: {
        setExpenseShowModal : (state, {payload}) => {
            return {...state, showModal: payload}
        },
    },
    extraReducers: {
        [fetchAsyncExpense.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                items: payload
            }
        }
    },
})


export const getExpenses = (state) => state.expense.items
export const getExpenseShowModal = (state) => state.expense.showModal
export const {setExpenseShowModal} = expenseSlice.actions

export default expenseSlice.reducer

