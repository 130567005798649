import {createAsyncThunk} from "@reduxjs/toolkit";
import {setLoading, setProgressDialog} from "../utility";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {notificationSuccessMessage} from "../../../common/config/message";
import {cancelJntOrderApi, createJntOrderApi, printJntOrderApi, trackingJntOrderApi} from "../../../common/api/jntApi";
import moment from "moment/moment";
import {fetchAsyncReportSipping} from "../report/reportThunk";

export const createAsyncJntOrder = createAsyncThunk('jnt/createAsyncJntOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, createJntOrderApi(data.orderId, data.data))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        if (typeof data.callback !== `undefined`) {
            data.callback(false)
        }
        if(typeof data.filter !== `undefined`) {
            const f = {...data.filter}
            f.start = moment(f.start).format("YYYY-MM-DD")
            f.end = moment(f.end).format("YYYY-MM-DD")
            baseThunk.dispatch(fetchAsyncReportSipping(f))
        }
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})

export const cancelAsyncJntOrder = createAsyncThunk('jnt/cancelAsyncJntOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, cancelJntOrderApi(data.orderId))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        if(typeof data.filter !== `undefined`) {
            const f = {...data.filter}
            f.start = moment(f.start).format("YYYY-MM-DD")
            f.end = moment(f.end).format("YYYY-MM-DD")
            baseThunk.dispatch(fetchAsyncReportSipping(f))
        }
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})
export const trackingAsyncJntOrder = createAsyncThunk('jnt/trackingAsyncJntOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, trackingJntOrderApi(data.id))
        baseThunk.dispatch(setProgressDialog(false))
        // notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})

export const printAsyncJntOrder = createAsyncThunk('jnt/printAsyncJntOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, printJntOrderApi(data))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})
