import {
    checkDomainConfigApi,
    deleteUserApi,
    fetchUsersApi,
    getProfileApi,
    getUserApi,
    loginUserApi,
    newUserApi,
    updateUserApi
} from "../../../common/api/userApi";
import {notificationSuccessMessage} from "../../../common/config/message";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {setModal, setProgressDialog, setRedirect} from "../utility";
import moment from "moment";
import {setDomainVerify, setTomanyLogin} from "./userSlice";
import {checkSubDomain} from "../../../common/api/gateway";

export const loginAsyncUser = createAsyncThunk(
    'users/loginAsyncUser',
    async ({username, password}, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            const response = await httpRequestFunc(baseThunk, loginUserApi(username, password))
            baseThunk.dispatch(setRedirect(`/dashboard`))
            baseThunk.dispatch(setProgressDialog(false))
            return {
                accessToken: response?.data.access_token,
                refreshToken: response?.data.refresh_token
            }
        } catch (e) {
            if (typeof e?.payload !== `undefined` && e.payload === `TOO_MANY_LOGIN`) {
                baseThunk.dispatch(setTomanyLogin())
            }
            baseThunk.dispatch(setProgressDialog(false))
            // alert(555)
        }
        // try {
        //     const response = await loginUserApi(username, password)
        //
        //     return {
        //         accessToken: response.data.access_token,
        //         refreshToken: response.data.refresh_token
        //     }
        // } catch (e) {
        //     notificationErrorMessage(e)
        //     const data = e?.response?.data
        //     throw rejectWithValue(data?.message)
        // }
    }
)

export const fetchAsyncUsers = createAsyncThunk(
    'users/fetchAsyncUsers',
    async ({filter}, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            const response = await httpRequestFunc(baseThunk, fetchUsersApi(filter))
            // const response = await fetchUsersApi(filter)
            baseThunk.dispatch(setProgressDialog(false))
            return response.data
        } catch (e) {
            baseThunk.dispatch(setProgressDialog(false))
        }
    }
)

export const getAsyncProfile = createAsyncThunk(
    'users/getAsyncUsers',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getProfileApi())
            baseThunk.dispatch(getDomainAsyncConfig())
            return response.data
        } catch (e) {

        }

    }
)


export const getAsyncUser = createAsyncThunk(
    'users/getAsyncUser',
    async ({id}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getUserApi(id))
            return response.data
        } catch (e) {

        }

    }
)

export const newAsyncUser = createAsyncThunk(
    'users/newAsyncUser',
    async ({data, filter}, baseThunk) => {

        // const response = httpRequestFunc(dispatch, newUserApi(data))

        try {
            // const response = await newUserApi(data)
            const response = await httpRequestFunc(baseThunk, newUserApi(data))
            if (response.status == 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                const user = response.data
                if (typeof filter !== `undefined`) {
                    baseThunk.dispatch(fetchAsyncUsers(filter))
                }
                baseThunk.dispatch(setModal(false))
                baseThunk.dispatch(setRedirect(`/user?t=${moment().unix()}`))
                return user
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const updateAsyncUser = createAsyncThunk(
    'users/updateAsyncUser',
    async ({data, filter}, baseThunk) => {
        try {
            // console.log(data)
            // const response = await updateUserApi(data.username, data)
            const response = await httpRequestFunc(baseThunk, updateUserApi(data.username, data))
            if (response.status == 200) {
                baseThunk.dispatch(setModal(false))
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                if (typeof filter !== `undefined`) {
                    baseThunk.dispatch(fetchAsyncUsers(filter))
                }
                // baseThunk.dispatch(fetchAsyncUsers({
                //     filter: {
                //         page: 1,
                //         pageSize: 15,
                //         role: data.role === "3" ? 'member' : 'admin-emp',
                //     }
                // }))
                const user = response.data
                return user
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)


export const deleteAsyncUser = createAsyncThunk(
    'users/deleteAsyncUser',
    async ({id}, baseThunk) => {
        try {
            // const response = await deleteUserApi(id)
            const response = await httpRequestFunc(baseThunk, deleteUserApi(id))
            if (response.status == 200) {
                notificationSuccessMessage(`ทำรายการสำเร็จ`)
                return id
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)
export const getDomainAsyncConfig = createAsyncThunk(
    'users/getDomainAsyncConfig',
    async (data, baseThunk) => {
        try {
            const domain = checkSubDomain()
            // if (typeof domain === `undefined` || domain === `test` || domain === `` || domain === `localhost:3000`) {
            //     domain = `main`
            // }
            const response = await httpRequestFunc(baseThunk, checkDomainConfigApi(domain))
            if (response.status == 200) {
                baseThunk.dispatch(setDomainVerify(1))
                return response.data
            }
        } catch (e) {
            baseThunk.dispatch(setDomainVerify(2))
        }
    }
)
