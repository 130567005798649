import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {setModal, setProgressDialog} from "../utility";
import {
    fetchDashboardReportApi,
    fetchReportCommissionApi,
    fetchReportMonthlyApi, fetchReportProductApi,
    fetchReportShippingApi
} from "../../../common/api/reportApi";

export const fetchAsyncReportMonthly = createAsyncThunk('report/fetchAsyncReportMonthly', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, fetchReportMonthlyApi(filter))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})

export const fetchAsyncReportProduct = createAsyncThunk('report/fetchAsyncReportProduct', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, fetchReportProductApi(filter))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        console.log(response.data)
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})

export const fetchAsyncCommission = createAsyncThunk('report/fetchAsyncCommission', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, fetchReportCommissionApi(filter))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})
export const fetchAsyncReportSipping = createAsyncThunk('report/fetchAsyncSipping', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, fetchReportShippingApi(filter))
        // baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})

export const fetchAsyncReportDashboard = createAsyncThunk('report/fetchAsyncReportDashboard', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, fetchDashboardReportApi(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})

