const PageDenied = () => {
    return (
        <div className="container">
            <div className="box my-5">
                <div className="py-5 text-center">
                    <h3>คุณไม่มีสิทธิ์ เข้าถึงข้อมูลในหน้าดังกล่าว</h3>
                    <p>กรุณาติดต่อผู้ดูแลระบบของท่าน</p>
                </div>
            </div>
        </div>
    )
}

export default PageDenied
