import {createSlice} from "@reduxjs/toolkit";
import {cancelAsyncJntOrder, createAsyncJntOrder, printAsyncJntOrder, trackingAsyncJntOrder} from "./jntThunk";
import {JntErrorsCode} from "../../../common/config/app";

const initialState = {
    selectedItems: [],
    tracking: {},
    pdf: ``,
}
const jntSlice = createSlice({
    name: `jnt`,
    initialState: initialState,
    reducers: {
        setSelectedOrderItems: (state, {payload}) => {
            return {
                ...state,
                selectedItems: payload
            }
        },
        setPdfUrl: (state, {payload}) => {
            return {
                ...state,
                pdf: payload
            }
        },
    },
    extraReducers: {
        [createAsyncJntOrder.fulfilled]: (state, {payload}) => {
            if (typeof payload !== `undefined` && payload.length > 0) {
                // console.log(payload)
                return {
                    ...state,
                    selectedItems: state.selectedItems.map(order => {
                        let o = order
                        payload.map(p => {
                            const item = p?.responseitems[0] ?? null
                            // console.log(p)
                            // console.log(item)
                            if (item?.txlogisticid == order.id) {
                                if (item?.success === "true") {
                                    o = {
                                        ...order,
                                        pno: item?.mailno ?? `Updated : ${item?.sortingcode}`,
                                        t: `success`,
                                        error: null,
                                        errMessage: null
                                    }
                                } else {
                                    o = {
                                        ...order,
                                        pno: '',
                                        t: 'error',
                                        error: item?.reason,
                                        errMessage: JntErrorsCode[item?.reason] ?? ''
                                    }
                                }
                            }
                        })
                        return o
                    })
                }
            }
        },
        [cancelAsyncJntOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                selectedItems: state.selectedItems.map(order => {
                    let o = order
                    payload.map(p => {
                        // const item = p[0] ?? null
                        if (p.id == order.id) {
                            if (p.pno === "") {
                                o = {...p, cancelTracking: "success"}
                            } else {
                                o = {...p, cancelTracking: "error"}
                            }
                        }
                    })
                    return o
                })
            }
        },
        [trackingAsyncJntOrder.fulfilled]: (state, {payload}) => {
            // console.log(payload?.responseitems[0]?.tracesList[0]?.details)
            if (typeof payload?.responseitems !== `undefined` && payload?.responseitems?.length > 0 && Array.isArray(payload?.responseitems)) {
                return {...state, tracking: payload?.responseitems[0]?.tracesList[0]?.details}
            }

        },
        [printAsyncJntOrder.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                pdf: payload.url
            }
        },
    },
})

export const getSelectedOrderItems = (state) => state.jnt.selectedItems
export const getTracking = (state) => state.jnt.tracking
export const getPdfUrl = (state) => state.jnt.pdf
export const jntState = (state) => state.jnt
export const {setSelectedOrderItems, setPdfUrl} = jntSlice.actions
export default jntSlice.reducer
