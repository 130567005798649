import {createAsyncThunk} from "@reduxjs/toolkit";
import {deleteCategoryApi, fetchCategoryApi, newCategoryApi, updateCategoryApi} from "../../../common/api/categoryApi";
import {notificationErrorMessage, notificationSuccessMessage} from "../../../common/config/message";
import {setCategoryShowModal} from "./categorySlice";
import {setProgressDialog} from "../utility";
import {httpRequestFunc} from "../../../common/config/httpClient";

export const fetchAsyncCategory = createAsyncThunk(
    'category/fetchAsyncCategory',
    async ({filter}, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            // const response = await fetchCategoryApi(filter)
            const response = await httpRequestFunc(baseThunk,  fetchCategoryApi(filter))
            baseThunk.dispatch(setCategoryShowModal(false))
            baseThunk.dispatch(setProgressDialog(false))
            return response.data
        } catch (e) {
            console.log(e)
            baseThunk.dispatch(setProgressDialog(false))
        }
    }
)


export const newAsyncCategory = createAsyncThunk(
    'category/newAsyncCategory',
    async ({category}, baseThunk) => {
        try {
            // const response = await newCategoryApi(category)
            const response = await httpRequestFunc(baseThunk,  newCategoryApi(category))
            if (response.status === 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                baseThunk.dispatch(fetchAsyncCategory({}))
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const updateAsyncCategory = createAsyncThunk(
    'category/updateAsyncCategory',
    async ({category}, baseThunk) => {
        // console.log(category)
        try {
            // const response = await updateCategoryApi(category.id, category)
            const response = await httpRequestFunc(baseThunk,  updateCategoryApi(category.id, category))
            if (response.status === 200) {
                notificationSuccessMessage(`แก้ไขข้อมูลสำเร็จ`)
                baseThunk.dispatch(fetchAsyncCategory({}))
            }
        } catch (e) {
            console.log(e)
            // notificationErrorMessage(e)
        }
    }
)


export const deleteAsyncCategory = createAsyncThunk(
    'category/deleteAsyncCategory',
    async (id, baseThunk) => {
        try {
            // const response = await deleteCategoryApi(id)
            const response = await httpRequestFunc(baseThunk,  deleteCategoryApi(id))
            if (response.status === 200) {
                // notificationSuccessMessage(`ทำรายการสำเร็จ`)
                baseThunk.dispatch(fetchAsyncCategory({}))
            }
        } catch (e) {
            console.log(e)
            // notificationErrorMessage(e)
        }
    }
)
