import {httpObjQueryString} from "../config/httpClient";
import {checkSubDomain, setDataParams, SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay


export const fetchOrderApi = (filter) => {
    return httpClient.get(`/order?${httpObjQueryString(filter)}`)
}

export const fetchOrderTeleSaleApi = (filter) => {
    return httpClient.get(`/order/telesale?${httpObjQueryString(filter)}`)
}

export const getOrderApi = (id) => {
    return httpClient.get(`/order/${id}`)
}

export const newOrderApi = (data) => {
    return httpClient.post(`/order`, setDataParams(data))
}

export const updateOrderApi = (data) => {
    return httpClient.put(`/order/${data.id}`, setDataParams(data))
}


export const updateOrderShippingApi = (data) => {
    return httpClient.put(`/order/${data.id}/shipping`, setDataParams(data))
}

export const updateOrderInfoApi = (data) => {
    return httpClient.put(`/order/${data.id}/info`, setDataParams(data))
}

export const updateOrderItemApi = (data) => {
    return httpClient.put(`/order/${data.id}/item`, setDataParams(data))
}

export const deleteOrderApi = (id) => {
    return httpClient.delete(`/order/${id}`)
}

export const deleteOrderItemApi = (id) => {
    return httpClient.delete(`/order/${id}/item`)
}

export const rollbackOrderApi = (id) => {
    return httpClient.put(`/order/${id}/rollback?status=send`)
}

export const openOrderApi = (data) => {
    return httpClient.post(`/line/order/open`, {
        address: data?.message ?? ''
    })
}
export const openOrderManualApi = (data) => {
    return httpClient.post(`/order/open`, setDataParams(data))
}
export const openOrderImportApi = (data) => {
    return httpClient.post(`/order/import`, setDataParams({
        orders: data
    }))
}


export const getOrderMessageApi = (filter) => {
    return httpClient.get(`/order/message?${httpObjQueryString(filter)}`)
}


export const updateOrderSendStatusApi = (id) => {
    return httpClient.put(`/order/${id}/status?status=sending`)
}

export const updateOrderStatusApi = (data) => {
    return httpClient.put(`/order/updates`, setDataParams(data))
}


export const printOrderReceiptApi = (data) => {
    const params = {...data, prefix: checkSubDomain()}
    return httpClient.post(`/print/pdf/receipt`, params)
}


export const checkDuplicateOrderApi = (filter) => {
    return httpClient.get(`/order/check?${httpObjQueryString(filter)}`)
}
