import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    addProductStockApi,
    deleteProductAllApi,
    deleteProductApi,
    fetchProductApi, fetchProductStockApi,
    newProductApi,
    updateProductApi
} from "../../../common/api/productApi";
import {notificationErrorMessage, notificationSuccessMessage} from "../../../common/config/message";
import {setProductShowModal} from "./productSlice";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {setProgressDialog} from "../utility";

export const fetchAsyncProduct = createAsyncThunk(
    'product/fetchAsyncProduct',
    async ({filter}, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            // const response = await fetchProductApi(filter)
            const response = await httpRequestFunc(baseThunk, fetchProductApi(filter))
            baseThunk.dispatch(setProductShowModal(false))
            baseThunk.dispatch(setProgressDialog(false))
            return response.data
        } catch (e) {
            console.log(e)
            baseThunk.dispatch(setProgressDialog(false))
        }
    }
)
export const fetchAsyncProductStock = createAsyncThunk(
    'product/fetchAsyncProductStock',
    async (filter, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            // const response = await fetchProductApi(filter)
            const response = await httpRequestFunc(baseThunk, fetchProductStockApi(filter))
            baseThunk.dispatch(setProductShowModal(false))
            baseThunk.dispatch(setProgressDialog(false))
            return response.data
        } catch (e) {
            console.log(e)
            baseThunk.dispatch(setProgressDialog(false))
        }
    }
)

export const fetchAsyncProductSearch = createAsyncThunk(
    'product/fetchAsyncProductSearch',
    async (filter, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            // const response = await fetchProductApi(filter)
            const response = await httpRequestFunc(baseThunk, fetchProductApi(filter))
            baseThunk.dispatch(setProgressDialog(false))
            return response.data
        } catch (e) {
            console.log(e)
            baseThunk.dispatch(setProgressDialog(false))
        }
    }
)


export const newAsyncProduct = createAsyncThunk(
    'product/newAsyncProduct',
    async (data, baseThunk) => {
        try {
            // const response = await newProductApi(data)
            const response = await httpRequestFunc(baseThunk, newProductApi(data))
            if (response.status == 200) {
                notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
                baseThunk.dispatch(fetchAsyncProduct({
                    filter: {
                        showUnit: 1
                    }
                }))
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const updateAsyncProduct = createAsyncThunk(
    'product/updateAsyncProduct',
    async (data, baseThunk) => {
        // console.log(data)
        try {
            const response = await httpRequestFunc(baseThunk, updateProductApi(data.id, data))
            // const response = await updateProductApi(data.id, data)
            if (response?.status == 200) {
                notificationSuccessMessage(`แก้ไขข้อมูลสำเร็จ`)
                baseThunk.dispatch(fetchAsyncProduct({}))
            }
        } catch (e) {
            // console.log(e)
            // notificationErrorMessage(e)
        }
    }
)


export const deleteAsyncProduct = createAsyncThunk(
    'product/deleteAsyncProduct',
    async (id, baseThunk) => {
        try {
            // const response = await deleteProductApi(id)
            const response = await httpRequestFunc(baseThunk, deleteProductApi(id))
            if (response.status == 200) {
                notificationSuccessMessage(`ทำรายการสำเร็จ`)
                baseThunk.dispatch(fetchAsyncProduct({}))
            }
        } catch (e) {
            // console.log(e)
            // notificationErrorMessage(e)
        }
    }
)
export const deleteAsyncProductAll = createAsyncThunk(
    'product/deleteAsyncProductAll',
    async (ids, baseThunk) => {
        // console.log(ids)
        try {
            // const response = await deleteProductAllApi(ids)
            const response = await httpRequestFunc(baseThunk, deleteProductAllApi(ids))
            if (response.status == 200) {
                notificationSuccessMessage(`ทำรายการสำเร็จ`)
                baseThunk.dispatch(fetchAsyncProduct({}))
            }
        } catch (e) {
            console.log(e)
            // notificationErrorMessage(e)
        }
    }
)


export const addAsyncProductStock = createAsyncThunk(
    'product/addAsyncProductStock',
    async (data, baseThunk) => {
        baseThunk.dispatch(setProgressDialog(true))
        try {
            // const response = await fetchProductApi(filter)
            const response = await httpRequestFunc(baseThunk, addProductStockApi(data))
            baseThunk.dispatch(setProgressDialog(false))
            baseThunk.dispatch(fetchAsyncProductStock({
                id: data.id,
            }))
            return response.data
        } catch (e) {
            console.log(e)
            baseThunk.dispatch(setProgressDialog(false))
        }
    }
)
