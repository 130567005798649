import {httpObjQueryString} from "../config/httpClient";
import localStorageService from "../../services/localStorageService";
import {setDataParams, SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const createFlashOrderApi = (order, data) => {
    const localStorage = localStorageService.getService()
    const domain = localStorage.getConfig()
    const orderId = {
        orderId: order,
        address: data.address,
        category: data.category,
        expressCategory: data.shipping,
        freightInsureEnabled: data.freightInsureEnabled ?? "0",
        insureDeclareValue: data.insureDeclareValue ?? "",
        prefix: domain?.prefix ?? '',
    }
    // console.log(orderId)
    return httpClient.post(`/flashexpress/order/create`, orderId)
}

export const cancelFlashOrderApi = (data) => {
    const orderId = {
        orderId: data
    }
    return httpClient.post(`/flashexpress/order/cancel`, orderId)
}

export const getFlashTrackingApi = (code) => {
    return httpClient.get(`/flashexpress/order/tracking/${code}`)
}

export const getFlashPrintTrackingApi = (data, size = 0) => {
    // alert(size)
    const orderId = {
        orderId: data, size: size
    }
    return httpClient.post(`/flashexpress/order/tracking/print`, orderId)
}

export const flashSinglePrintTrackingApi = (code) => {
    return httpClient.get(`/flashexpress/order/tracking/${code}/print`, {
        responseType: "blob",
    })
}

export const getFlashPrintHistoryApi = (filter) => {
    return httpClient.get(`/flashexpress/order/tracking/print?${httpObjQueryString(filter)}`)
}


export const getFlashNotificationsApi = (filter) => {
    return httpClient.get(`/flashexpress/notifications?${httpObjQueryString(filter)}`)
}

export const openFlashNotifyApi = (data) => {
    return httpClient.post(`/flashexpress/notify`, setDataParams(data))
}

export const cancelFlashNotifyApi = (id) => {
    return httpClient.delete(`/flashexpress/notify/${id}/cancel`)
}


export const getFlashWarehouseApi = () => {
    return httpClient.get(`/flashexpress/ka/warehouse`)
}


export const createFlashCourierApi = (data) => {
    let dt = {
        ka_warehouse_id: data.ka_warehouse_id, remark: data.remark, estimate_parcel_number: data.estimate_parcel_number,
    }

    if (typeof data.staff_id !== `undefined` && data.staff_id !== `` && data.staff_id !== null) {
        dt.staff_id = data.staff_id
    }

    return httpClient.post(`/flashexpress/ka/courier`, dt)
}
export const getFlashCourierApi = (filter) => {
    return httpClient.get(`/flashexpress/ka/courier?${httpObjQueryString(filter)}`)
}

export const cancelFlashCourierApi = (id) => {
    return httpClient.delete(`/flashexpress/ka/courier/${id}/cancel`)
}


export const getFlashKACodBills = (filter) => {
    return httpClient.get(`/flashexpress/ka/cod/bills?${httpObjQueryString(filter)}`)
}

export const getFlashKACodBillsDownload = (filter) => {
    return httpClient.post(`/flashexpress/ka/cod/bills`, filter)
}

export const getFlashKACodParcels = (pno) => {
    return httpClient.get(`/flashexpress/ka/cod/parcels/${pno}`)
}

export const getFlashCodDayTotalApi = (filter) => {
    return httpClient.get(`/flashexpress/ka/cod/day?${httpObjQueryString(filter)}`)
}

export const getFlashKACodPayment = (filter) => {
    return httpClient.get(`/flashexpress/ka/cod/payment?${httpObjQueryString(filter)}`)
}

export const getFlashKAPod = (filter) => {
    return httpClient.get(`/flashexpress/ka/pod?${httpObjQueryString(filter)}`)
}
export const getFlashKAPodDownload = (filter) => {
    return httpClient.post(`/flashexpress/ka/pod`, setDataParams(filter))
}
