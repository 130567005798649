import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    deleteAddressApi, detectAddressApi, fetchAddressApi, newAddressApi, updateAddressApi
} from "../../../common/api/addressApi";
import {notificationSuccessMessage} from "../../../common/config/message";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {setModal, setProgressDialog} from "../utility";

export const fetchAsyncAddress = createAsyncThunk('address/fetchAsyncAddress', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        // const response = await fetchAddressApi(filter)
        const response = await httpRequestFunc(baseThunk, fetchAddressApi(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        // console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})


export const addAsyncAddress = createAsyncThunk('address/addAsyncAddress', async (address, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, typeof address.id !== `undefined` ? updateAddressApi(address) : newAddressApi(address))
        if (response.status === 200) {
            notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
            const filter = {
                type: address.type
            }
            baseThunk.dispatch(setModal(false))
            baseThunk.dispatch(fetchAsyncAddress(filter))
        }
    } catch (e) {
        console.log(e)
    }
})


export const deleteAsyncAddress = createAsyncThunk('address/deleteAsyncAddress', async (address, baseThunk) => {
    try {
        const response = await httpRequestFunc(baseThunk, deleteAddressApi(address.id))
        if (response.status === 200) {
            notificationSuccessMessage(`บันทึกข้อมูลสำเร็จ`)
            const filter = {
                type: address.type
            }
            baseThunk.dispatch(fetchAsyncAddress(filter))
        }
    } catch (e) {
        console.log(e)
    }
})


export const fetchAsyncAddressDetect = createAsyncThunk('address/fetchAsyncAddressDetect', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, detectAddressApi(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
    }
})
