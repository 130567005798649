import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {
    cancelFlashCourierApi,
    cancelFlashNotifyApi,
    cancelFlashOrderApi,
    createFlashCourierApi,
    createFlashOrderApi,
    getFlashCodDayTotalApi,
    getFlashCourierApi,
    getFlashKACodBills, getFlashKACodParcels, getFlashKACodPayment, getFlashKAPod,
    getFlashNotificationsApi,
    getFlashPrintHistoryApi,
    getFlashPrintTrackingApi,
    getFlashTrackingApi,
    getFlashWarehouseApi,
    openFlashNotifyApi
} from "../../../common/api/flashExpressApi";
import {setLoading, setModal, setProgressDialog} from "../utility";
import {notificationSuccessMessage} from "../../../common/config/message";
import moment from "moment";
import {fetchAsyncReportSipping} from "../report/reportThunk";

export const getAsyncFlashExpressTracking = createAsyncThunk('flash/getAsyncFlashExpressTracking', async (code, baseThunk) => {

    try {
        const response = await httpRequestFunc(baseThunk, getFlashTrackingApi(code))
        return response.data
    } catch (e) {
        console.log(e)
    }

})


export const createAsyncFlashExpressOrder = createAsyncThunk('flash/createAsyncFlashExpressOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, createFlashOrderApi(data.orderId, data.data))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        if (typeof data.callback !== `undefined`) {
            data.callback(false)
        }
        if(typeof data.filter !== `undefined`) {
            const f = {...data.filter}
            f.start = moment(f.start).format("YYYY-MM-DD")
            f.end = moment(f.end).format("YYYY-MM-DD")
            baseThunk.dispatch(fetchAsyncReportSipping(f))
        }
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})

export const cancelAsyncFlashExpressOrder = createAsyncThunk('flash/cancelAsyncFlashExpressOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, cancelFlashOrderApi(data.orderId))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลยกเลิก Tracking แล้ว กรุณาตรวจสอบรายการที่ยกเลิก")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        if(typeof data.filter !== `undefined`) {
            const f = {...data.filter}
            f.start = moment(f.start).format("YYYY-MM-DD")
            f.end = moment(f.end).format("YYYY-MM-DD")
            baseThunk.dispatch(fetchAsyncReportSipping(f))
        }
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})


export const printAsyncFlashTrackingOrder = createAsyncThunk('flash/printAsyncFlashTrackingOrder', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashPrintTrackingApi(data.orderId, data?.size))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ส่งข้อมูลสร้าง Tracking แล้ว กรุณาตรวจสอบรายการสั่งซื้อ")
        baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        console.log(e)
    }
})
export const getAsyncFlashTrackingPrint = createAsyncThunk('flash/getAsyncFlashTrackingPrint', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashPrintHistoryApi(data))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})


export const getAsyncFlashWarehouse = createAsyncThunk('flash/getAsyncFlashWarehouse', async (data, baseThunk) => {
    try {
        // alert(55)
        const response = await httpRequestFunc(baseThunk, getFlashWarehouseApi())
        return response.data
    } catch (e) {
        console.log(e)
    }
})

export const getAsyncFlashNotifications = createAsyncThunk('flash/getAsyncFlashNotifications', async (filter, baseThunk) => {
    try {
        // alert(55)
        const response = await httpRequestFunc(baseThunk, getFlashNotificationsApi(filter))
        return response.data
    } catch (e) {
        console.log(e)
    }
})


export const openAsyncFlashNotify = createAsyncThunk('flash/openAsyncFlashNotify', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, openFlashNotifyApi(data))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("บันทึกข้อมูลสำเร็จ")
        baseThunk.dispatch(getAsyncFlashNotifications({
            date: moment().format("YYYY-MM-DD")
        }))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        console.log(e)
    }
})

export const cancelAsyncFlashNotify = createAsyncThunk('flash/cancelAsyncFlashNotify', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, cancelFlashNotifyApi(data.id))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("บันทึกข้อมูลสำเร็จ")
        baseThunk.dispatch(getAsyncFlashNotifications({
            date: moment().format("YYYY-MM-DD")
        }))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        console.log(e)
    }
})


export const createAsyncFlashCourier = createAsyncThunk('flash/createAsyncFlashCourier', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, createFlashCourierApi(data))
        baseThunk.dispatch(setModal(false))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("บันทึกข้อมูลสำเร็จ")
        baseThunk.dispatch(getAsyncFlashCourier({
            date: moment().format("YYYY-MM-DD")
        }))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        console.log(e)
    }
})


export const getAsyncFlashCourier = createAsyncThunk('flash/getAsyncFlashCourier', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashCourierApi(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})

export const cancelAsyncFlashCourier = createAsyncThunk('flash/cancelAsyncFlashCourier', async (data, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, cancelFlashCourierApi(data.id))
        baseThunk.dispatch(setProgressDialog(false))
        notificationSuccessMessage("ยกเลิกข้อมูลสำเร็จ")
        const f = {
            ...data.filter,
            start: moment(data?.filter?.start).format(`YYYY-MM-DD`),
            end: moment(data?.filter?.end).format(`YYYY-MM-DD`),
        }
        baseThunk.dispatch(getAsyncFlashCourier(f))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        console.log(e)
    }
})


export const getAsyncFlashKACodBills = createAsyncThunk('flash/getAsyncFlashKACodBills', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashKACodBills(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        console.log(e)
    }
})

export const getAsyncFlashKACodDayTotal = createAsyncThunk('flash/getAsyncFlashKACodDayTotal', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashCodDayTotalApi(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
        baseThunk.dispatch(setProgressDialog(false))
    }
})
export const getAsyncFlashKACodParcels = createAsyncThunk('flash/getAsyncFlashKACodParcels', async (pno, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashKACodParcels(pno), false)
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
    }
    baseThunk.dispatch(setProgressDialog(false))
})
export const getAsyncFlashKACodPayment = createAsyncThunk('flash/getAsyncFlashKACodPayment', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashKACodPayment(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
    }
    baseThunk.dispatch(setProgressDialog(false))
})
export const getAsyncFlashKAPod = createAsyncThunk('flash/getAsyncFlashKAPod', async (filter, baseThunk) => {
    baseThunk.dispatch(setProgressDialog(true))
    try {
        const response = await httpRequestFunc(baseThunk, getFlashKAPod(filter))
        baseThunk.dispatch(setProgressDialog(false))
        return response.data
    } catch (e) {
        console.log(e)
    }
    baseThunk.dispatch(setProgressDialog(false))
})
